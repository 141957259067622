import React from 'react'
import styled from 'styled-components'
import Box from 'components/Box'

const Background = styled(Box)`
  position: relative;
  background-repeat: no-repeat;
  background-size: ${props => props.size};
  background-position: ${props => props.backgroundPosition};
  background-image: ${({ src }) => src ? `url(${src})` : 'none'};
`

Background.defaultProps = {
  size: 'cover',
  backgroundPosition: 'center center',
}

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${props => props.bg};
  opacity: ${props => props.opacity};
`

Overlay.defaultProps = {
  bg: '#000',
  opacity: 0,
}

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
`

export default ({ overlay, children, ...rest }) => (
  <Background {...rest}>
    {overlay && <Overlay {...overlay} />}
    {children && <Content >{children}</Content>}
  </Background>
)