const baige = ['#8a5c61', '#71383e', '#5d2e33']
const purple = ['#6b5460', '#4b2f3d', '#3e2732']
const blue = ['#45c2d1', '#00acc1', '#007e8d']
const green = ['#7cc47f', '#4caf50', '#38803b']
const yellow = ['#f1b885', '#efa96b', '#c48b58']
const red = ['#f7766c', '#f44336', '#b23128']
const light = ['#fff', '#f8f8f8', '#efefef', '#e1e1e1', '#9c9c9c']
const dark = ['#666', '#545454', '#373838', '#292b2d', '#1e1f21']

const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64]
const space = [0, 4, 8, 16, 32, 64, 128, 256]

export default {
  breakpoints: ['768px', '1200px', '1600px'],
  fontSizes,
  colors: {
    primary: baige,
    secondary: purple,
    accent: blue,
    success: green,
    warning: yellow,
    error: red,
    light,
    dark,
  },
  space,
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: shadow('6, 6, 6', 'sm'),
    large: shadow('6, 6, 6', 'lg'),
  },
  textStyles: {
    header: {
      fontFamily: 'Roboto',
      fontSize: fontSizes[5],
      fontWeight: 400
    },
  },
  colorStyles: {
    primary: { color: baige[1] },
    secondary: { color: blue[1] },
    success: { color: green[1] },
    warning: { color: yellow[1] },
    error: { color: red[1] },
    dark: {
      color: dark[0],
      borderColor: light[3],
      '&:hover': {
        borderColor: dark[0],
      }
    },
    light: {
      color: light[3],
      borderColor: dark[0],
      '&:hover': {
        color: light[0],
        borderColor: light[0],
        paddingLeft: space[2],
      }
    },
  },
  boxes: {
    primary: box(light[0], baige[1], baige[2], '233,30,99'),
    secondary: box(light[0], blue[1], null, '0,172,193'),
    success: box(light[0], green[1], null, '76,175,80'),
    warning: box(light[0], yellow[1], null, '255,152,0'),
    error: box(light[0], red[1], null, '244,67,54'),
    dark: box(light[0], dark[3]),
    light: hoverBox(light[4], light[0]),
    lightRaised: box(light[4], light[0], '0,0,0'),
  },
  buttons: {
    default: button(dark[3], light[0], true),
    primary: button(light[0], blue[1], false),
    secondary: button(light[0], blue[1], false),
    plain: button(dark[3], light[0], false),
  },
}

function shadow(rgb, size) {
  if (!rgb) { return 'none' }

  switch (size) {
    case 'lg':
      return `0 14px 26px -12px rgba(${rgb}, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(${rgb}, 0.2)`
    default:
      return `0 2px 2px 0 rgba(${rgb}, 0.14), 0 3px 1px -2px rgba(${rgb}, 0.2), 0 1px 5px 0 rgba(${rgb}, 0.12)`
  }
}

function hoverBox(color, bgStart, bgEnd, boxShadow) {
  let background = bgStart

  if (bgEnd) {
    background = `linear-gradient(60deg, ${bgStart}, ${bgEnd})`
  }

  return {
    color,
    background,
    boxShadow: boxShadow ? shadow(boxShadow, 'lg') : 'none',
    borderWidth: boxShadow ? 0 : '1px',
    borderStyle: 'solid',
    borderColor: light[3],
    transition: 'color 0.2s ease-out, border-color 0.2s ease-out',
    '&:hover': {
      borderColor: dark[1],
      color: boxShadow ? color : dark[1],
    },
  }
}

function box(color, background, boxShadow) {
  return {
    color,
    background,
    boxShadow: boxShadow ? shadow(boxShadow, 'lg') : 'none',
    transition: 'color 0.2s ease-out',
  }
}

function button(color, backgroundColor, hoverEffect) {
  const style = {
    padding: `${space[2]}px ${space[3]}px`,
    color,
    backgroundColor,
    fontSize: fontSizes[2],
    border: `1px solid ${light[3]}`,
    transition: 'box-shadow 0.3s ease-out, color 0.3s ease-out',
    'will-change': 'box-shadow',
  }

  if (hoverEffect) {
    style['&:hover'] = {
      color: light[3],
      boxShadow: `inset 0 -3.25em 0 0 ${dark[4]}`,
    }
  }

  return style
}