import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Image from 'components/Image'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const url = process.env.REACT_APP_STATIC_URL

function Quality({ intl }) {
  const translate = getTranslate(intl)

  useEffect(() => {
    document.title = translate('quality.title')
  }, [])

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('quality.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.0" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', 1, 1]}>
          <Image
            src={url + 'misc/bg-06.jpg'}
            maxWidth={300}
            height={800}
          />
        </Box>
        <Box
          bg="light.0"
          flex={['100%', 1, 4]}
          p={[2, 3, 4]}
        >
          <Text as="h2">專業品質</Text>
          <Text as="p">
            顧客滿意度是德國達麗堅持的指標，達麗每位員工都堅守著自己的崗位，為了給您最專業的品質。
          </Text>
          <ul>
            <li>以顧客的感受為優先考量，提供量化的高品質服務</li>
            <li>不斷優化製程以確保產品的完成的高效率</li>
            <li>加強產品效能滿足不同顧客需求</li>
            <li>精實的員工教育訓練，教導專業品質的重要性</li>
            <li>每位員工都對產品負責，堅守產品最高品質</li>
            <li>根據新的科學與科技發現來製作新產品</li>
            <li>提供舒適環境以確保員工能在良好環境下製作出高品質的產品</li>
          </ul>
          <Text as="p">
            德國達麗通過ISO9001驗證，IFS HPC家用清潔與身體護理產品國際認證，BRC全球標準驗證，為您和家人的健康把關，不遺餘力。
          </Text>
        </Box>
      </Flex>
    </Container>
  )
}

export default injectIntl(Quality)