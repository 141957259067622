import React, { useState, useEffect, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import { initialState, getModel } from 'models/orderList'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Text from 'components/Text'
import Link from 'components/Link'
import Table from 'components/Table'
import LoadingIcon from 'components/LoadingIcon'

function OrderList({ intl }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session, app })

  useEffect(() => {
    document.title = translate('orderList.title')
    model.handleFetch()
    model.setBreadcrumb()
  }, [])

  if (state.length === 0) {
    return <LoadingIcon />
  }

  return (
    <Table
      width={1}
      bg="light.0"
      columns={[
        {
          id: 'id',
          label: translate('orderList.table.orderNo'),
          render: row => <Link color="accent.1" url={`/members/orders/${row.id}`}>{row.id}</Link>
        },
        {
          id: 'transDate',
          label: translate('orderList.table.transDate'),
        },
        {
          id: 'totalAmount',
          label: translate('orderList.table.totalAmount'),
          render: row => <Text>NT.{row.totalAmount}</Text>
        },
        {
          id: 'status',
          label: translate('orderList.table.status'),
          render: row => {
            switch (row.status) {
              case 'ACTIVE':
                return translate('orderList.status.active')
              default:
                return translate('orderList.status.pending')
            }
          }
        },
      ]}
      rows={state}
    />
  )
}

export default injectIntl(OrderList)