import { request } from 'utilities/graphql'
import { BREADCRUMB_SET } from 'constants/actionType'

export const initialState = []

export function getModel({ state, setState, translate, session, app }) {
  return {
    handleFetch: handleFetch({ state, setState, session, app }),
    setBreadcrumb: setBreadcrumb({ app, translate }),
  }
}

function handleFetch({ setState, session, app }) {
  return async () => {
    const query = `
      query {
        listTickets {
          id
          transDate
          totalAmount
          status
        }
      }
    `
    const [ok, data] = await request({ query }, { session, app })
    if (!ok) { return }
    
    setState(data.listTickets)
  }
}

function setBreadcrumb({ app, translate }) {
  return () => {
    const items = [
      { label: 'Home', url: '/' },
      { label: translate('orderList.title') },
    ]

    app.dispatch({ type: BREADCRUMB_SET, items })
  }
}