import React, { useEffect } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Text from 'components/Text'
import Link from 'components/Link'
import Container from 'components/Container'
import Carousel from 'components/Carousel'
import SocialList from 'components/SocialList'
import Image from 'components/Image'
import AspectRatio from 'components/AspectRatio'

const url = process.env.REACT_APP_STATIC_URL

const TextBlock = styled(Text).attrs({
  flex: ['100%', '50%', 1, 1],
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
})``

TextBlock.defaultProps = {
  color: 'dark.0',
  bg: 'light.0',
}

const InfoBlock = ({ title, subTitle, bg, color, align, children }) => {
  const heading = (
    <TextBlock bg={bg} color={color}>
      <Text fontFamily="Roboto" fontSize={6} ml={-4}>{title}</Text>
      <Text fontFamily="Roboto" fontSize={5} ml={4} mt={-2}>{subTitle}</Text>
    </TextBlock>
  )
  return (
    <Flex flex={['100%', '100%', 1]} flexWrap="wrap">
      {align === 'right' && heading}
      <TextBlock bgColor="light.0" fontWeight={300}>
        {children}
      </TextBlock>
      {align === 'left' && heading}
    </Flex>
  )
}

const ImageBlock = ({ src }) => (
  <Flex flex={['100%', '100%', 1]}>
    <AspectRatio ratio={300 / 800}>
      <Image src={src} width={1} height="100%" />
    </AspectRatio>
  </Flex>
)

function Home({ intl }) {
  const translate = getTranslate(intl)

  useEffect(() => {
    document.title = translate('home.title')
  })

  return (
    <Container>
      <Flex flexWrap="wrap">
        <Box flex={['100%', '100%', 3, 3]}>
          <Carousel
            width={1}
            ratio={458 / 1197}
            slides={[
              <Link key="s1" width={1} height="100%" url="/products/6548760238789492736">
                <Image src={url + 'home/slide-08.png'} width={1} height="100%" />
              </Link>,
              <Link key="s2" width={1} height="100%" url="/products/6548767118496628736">
                <Image src={url + 'home/slide-09.png'} width={1} height="100%" />
              </Link>,
              <Link key="s3" width={1} height="100%" url="/products/6548765753334562816">
                <Image src={url + 'home/slide-10.png'} width={1} height="100%" />
              </Link>,
            ]}
          />
        </Box>
        <Flex
          flex={['100%', '100%', 1, 1]}
          ml={[0, 0, 1, 1]}
          flexWrap="wrap"
          alignContent="space-between"
        >
          <AspectRatio ratio={150 / 399}>
            <Link url="/products/6548768327777386496" width={1} height="100%">
              <Image src={url + 'home/ad-05.jpg'} width={1} height="100%" />
            </Link>
          </AspectRatio>
          <AspectRatio ratio={150 / 399}>
            <Link url="/products/6548771448268259328" width={1} height="100%">
              <Image src={url + 'home/ad-06.jpg'} width={1} height="100%" />
            </Link>
          </AspectRatio>
          <AspectRatio ratio={150 / 399}>
            <Link url="/products/6548768628773224448" width={1} height="100%">
              <Image src={url + 'home/ad-07.jpg'} width={1} height="100%" />
            </Link>
          </AspectRatio>
        </Flex>
      </Flex>
      <Flex py={3} justifyContent="flex-end">
        <SocialList />
      </Flex>
      <Flex flexWrap="wrap">
        <ImageBlock src={url + 'home/block-06.jpg'} />
        <InfoBlock title="Eco-friendly" bg="warning.1" color="dark.3" align="right">
          德國達麗專業洗劑深信，保護環境的方法之一就是有效率的使用能源，我們也認為這是讓我們成功的祕訣。對產品要求精益求精，將汙染與傷害降到最低
        </InfoBlock>
      </Flex>
      <Flex flexWrap="wrap">
        <InfoBlock title="Safty First" bg="primary.1" color="light.0" align="left">
          德國達麗嚴格把關員工安全健康，因為對己身嚴謹的要求，造就了我們企業的輝煌成功。達麗深信，提供安全健康的工作環境，是我們需盡的義務，也是完美產品的品質保證。
        </InfoBlock>
        <ImageBlock src={url + 'home/block-05.jpg'} />
      </Flex>
      <Flex flexWrap="wrap">
        <ImageBlock src={url + 'home/block-07.jpg'} />
        <InfoBlock title="Quality" subTitle="Assurance" bg="secondary.1" color="light.0" align="right">
          德國達麗通過ISO9001驗證，IFS HPC家用清潔與身體護理產品國際認證，BRC全球標準驗證，為您和家人的健康把關，不遺餘力。
        </InfoBlock>
      </Flex>
    </Container>
  )
}

export default injectIntl(Home)