import { CART_CLEAR } from 'constants/actionType'

export function getModel({ app }) {
  return {
    removeCheckoutItems: removeCheckoutItems({ app }),
  }
}

function removeCheckoutItems({ app }) {
  return async () => {
    app.dispatch({ type: CART_CLEAR })
  }
}