import React, { useState, useEffect, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import LoadingIcon from 'components/LoadingIcon'
import ProductCard from 'components/ProductCard'
import { initialState, getModel } from 'models/productList'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'

function ProductList({ intl }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, session, app, translate })
  const url = new URL(window.location.href)
  const categoryId = url.searchParams.get('category')

  useEffect(() => {
    document.title = translate('category.title')
    model.handleFetch()
    model.setBreadcrumb(categoryId)
  }, [categoryId])

  const filterProducts = item => categoryId ? (item.categoryId === categoryId) : true
  const items = state.filter(item => filterProducts(item))
  const count = items.length
  
  return (
    <>
      {(state.length === 0) ?
        <LoadingIcon />
        :
        items.map(item => (
          <ProductCard key={item.id} linkUrl={`/products/${item.id}`} {...item} />
        ))
      }
      <Box
        flex={[
          '0%', 
          (count % 2 === 0) ? '0%' : '50%',
          (count % 4 === 0) ? '0%' : (count % 4 === 1) ? '75%' : (count % 4 === 2) ? '50%' : '25%',
        ]}
      />
    </>
  )
}

export default injectIntl(ProductList)