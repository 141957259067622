import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Box from 'components/Box'

const Wrapper = styled(Box)`
  max-height: ${props => props.maxHeight}px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`

export default function ({ open, children }) {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  useEffect(() => {
    setHeight(open ? ref.current.scrollHeight : 0)
  })
  
  return (
    <Wrapper ref={ref} open={open} maxHeight={height}>
      {children}
    </Wrapper>
  )
}