import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Image from 'components/Image'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const url = process.env.REACT_APP_STATIC_URL

function Safty({ intl }) {
  const translate = getTranslate(intl)

  useEffect(() => {
    document.title = translate('safty.title')
  }, [])

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('safty.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.0" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', 1, 1]}>
          <Image
            src={url + 'misc/bg-05.jpg'}
            maxWidth={300}
            height={800}
          />
        </Box>
        <Box
          bg="light.0"
          flex={['100%', 1, 4]}
          p={[2, 3, 4]}
        >
          <Text as="h2">安全與健康守則</Text>
          <Text as="p">
            德國達麗嚴格把關員工安全健康，因為對己身嚴謹的要求，造就了我們企業的輝煌成功。達麗深信，提供安全健康的工作環境，是我們需盡的義務，也是完美產品的品質保證。
          </Text>
          <ul>
            <li>一視同仁：每一位員工，派遣員工，供應商與股東都是達麗重要的一員，每一位同仁都在達麗的保護政策中，受到保障。</li>
            <li>確切落實：每位主管被要求確實執行他們應盡的義務，以身作則照顧每位員工的健康安全。</li>
            <li>激勵士氣：照顧員工的健康與安全提升了同仁的工作效率與動機，對達麗來說非常的重要。</li>
            <li>安全第一：每位同仁在合理的範圍內辛勤努力，盡一己之責，絕不從事危險行為。</li>
            <li>適才適所：每位同仁經過嚴謹的篩選，達麗依其長才所能發揮之處分派，達到適才適所，為達麗帶來最佳的成果。</li>
            <li>步步謹慎：從策劃，營運等各項活動細節著手，堅守政府法律與公司規範，所有的設備經過檢查與安全教育訓練，確保整體營運安全無虞。</li>
            <li>降低風險：將所有可能的危險因素降到最低，預防錯誤發生是達麗的第一優先考量，多方運作系統的防範措施更是達麗注重的環節。</li>
            <li>多元文化：德國達麗藉由與多國的公司跨國合作，讓我們充滿多元性，走在市場的尖端，動見觀瞻，對跨國員工的保障更是不在話下。</li>
          </ul>
          <Text as="p">
            所有員工健康安全準則目標與決策制定都遵循ISO9001，ISO14001國際認證最高標準。
          </Text>
        </Box>
      </Flex>
    </Container>
  )
}

export default injectIntl(Safty)