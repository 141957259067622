import { request } from 'utilities/graphql'
import { BREADCRUMB_SET } from 'constants/actionType'

export const initialState = {}

export function getModel({ state, setState, session, app, translate }) {
  return {
    handleFetch: handleFetch({ state, setState, session }),
    setBreadcrumb: setBreadcrumb({ app, translate }),
  }
}

function handleFetch({ state, setState, session }) {
  return async ({ id }) => {
    const variables = { id }
    const query = `
      query ($id: ID!) {
        getProduct(id: $id) {
          id
          categoryId
          name
          primaryImage
          images
          price
          spec
          desc
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) { return }

    setState(data.getProduct)
  }
}

function setBreadcrumb({ app, translate }) {
  return product => {
    if (!product.id) { return null }

    const category = app.state.categories.find(item => item.id === product.categoryId)
    const items = [
      { label: 'Home', url: '/' },
      { label: translate('category.title'), url: '/products' },
      { label: category.name, url: `/products?category=${category.id}` },
      { label: product.name }
    ]

    app.dispatch({ type: BREADCRUMB_SET, items })
  }
}