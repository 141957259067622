import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Image from 'components/Image'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const url = process.env.REACT_APP_STATIC_URL

function Environment({ intl }) {
  const translate = getTranslate(intl)

  useEffect(() => {
    document.title = translate('environment.title')
  }, [])

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('environment.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.0" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', 1, 1]}>
          <Image
            src={url + 'misc/bg-04.jpg'}
            maxWidth={300}
            height={800}
          />
        </Box>
        <Box
          bg="light.0"
          flex={['100%', 1, 4]}
          p={[2, 3, 4]}
        >
          <Text as="h2">環境與能源保護準則</Text>
          <Text as="p">
            德國達麗專業洗劑深信，保護環境的方法之一就是有效率的使用能源，我們也認為這是讓我們成功的祕訣。對產品要求精益求精，將汙染與傷害降到最低，因此我們依循以下的準則，從不懈怠:
          </Text>
          <ul>
            <li>嚴格要求遵守各項政府規範，無論是法律要求或者是環保組織要求</li>
            <li>德國達麗針對環保議題進行教育訓練，與全體同仁一起努力，鼓勵大家一齊為環境盡心盡力</li>
            <li>高端系統開發: 環保與能源節約系統的研發讓我們能精準量測，在過程中設定節約目標，製成記錄</li>
            <li>環保與能源的控制在集團中被視為最重要的一環，舉凡新產品，行銷活動與新流程制定，德國達麗皆將環境友善因素視為優先考量</li>
            <li>專業團隊諮詢: 我們向專業團隊諮詢，確保產品達到環保與能源使用的標準</li>
          </ul>
          <Text as="p">
            德國達麗集團董事會成員戮力遵行這些準則，所有目標與決策制定都遵循ISO9001，ISO14001，ISO50001驗證，以及AISE Charter for Sustainable Cleaning國際清潔劑協會認證最高標準。
          </Text>
        </Box>
      </Flex>
    </Container>
  )
}

export default injectIntl(Environment)