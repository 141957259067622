import React, { useState, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components'
import Box from 'components/Box'

const loading = keyframes`
  from { left: -200px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; }
  95% { left: 120%; }
  to { left: 100%; }
`

const hidden = css`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 500ms, opacity 500ms;
`

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 4px;
  width: 100%;
  ${props => props.hidden && hidden}
`

const Bar = styled(Box)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(200, 200, 200, 0.4);
`

const Progress = styled(Box)`
  position: absolute;
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: ${props => props.theme.colors.accent[1]};
  animation: ${loading} 2s linear infinite;
`

export default function LoadingBar({ loading }) {
  const [visible, setVisible] = useState(false)
  const [t, setTimer] = useState(null)

  useEffect(() => {
    if (loading && !visible) {
      setTimer(setTimeout(() => { setVisible(true) }, 250))
    }

    if (!loading && visible) {
      setVisible(false)
    }

    if (!loading) {
      clearTimeout(t)
    }
  }, [loading, visible])

  return (
    <Wrapper hidden={!visible}>
      <Bar><Progress /></Bar>
    </Wrapper>
  )
}