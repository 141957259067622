import React, { useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import { getModel } from 'models/payment'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const ecpayUrl = process.env.REACT_APP_API_ECPAY
const paymentType = 'CREDIT'

function Payment({ intl, match, history }) {
  const app = useContext(AppContext)
  const translate = getTranslate(intl)
  const model = getModel({ app })
  const getMessage = event => {
    const data = JSON.parse(event.data)
    console.log('data', data)
    if (data.RtnCode === '1') {
      model.removeCheckoutItems()
      history.push('/members/orders')
    }
  }

  useEffect(() => {
    document.title = translate('payment.title')
    window.addEventListener('message', getMessage)

    return function cleanup() {
      window.removeEventListener('message', getMessage)
    }
  }, [])

  const { merchantId, token } = match.params
  const src = `${ecpayUrl}/SP/SPCheckOut?MerchantID=${merchantId}&SPToken=${token}&PaymentType=${paymentType}&ts=${Date.now()}`
  
  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('payment.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.2" px={4} pb={4} justifyContent="center">
        <iframe
          id={'iframeECPay_' + paymentType}
          title="ecpay"
          src={src}
          frameBorder={0}
          width="710px"
          height="700px"
        />
      </Flex>
    </Container>
  )
}

export default injectIntl(withRouter(Payment))