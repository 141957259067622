import React, { useContext } from 'react'
import styled from 'styled-components'
import { Switch, Route, withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import Account from 'views/Account'
import OrderList from 'views/OrderList'
import OrderDetail from 'views/OrderDetail'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import List from 'components/List'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const Content = styled(Flex)`
  overflow-x: auto;
`

function MemberLayout({ intl, history }) {
  const { state } = useContext(AppContext)
  const translate = getTranslate(intl)
  const { token: { accessToken } } = state

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb items={state.breadcrumbs} />
      </Box>
      <Flex bg="light.1" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', 1, 1]} bg="light.1" mr={[0, 4]} mb={4}>
          <Text variant="dark" p={2} justifyContent="center">{translate('account.sidebar')}</Text>
          <List
            colors="dark"
            items={[
              {
                id: 'account',
                label: translate('account.title'),
                path: `/members/accounts`
              },
              {
                id: 'orderList',
                label: translate('orderList.title'),
                path: `/members/orders`
              },
            ]}
            onItemClick={item => history.push(item.path)}
          />
        </Box>
        <Content
          flex={['100%', 2, 4]}
          flexWrap="wrap"
          bg="light.0"
          color="dark.0"
          p={[2, 3, 4]}
        >
          {accessToken ?
            <Switch>
              <Route path="/members/accounts" component={Account} />
              <Route exact path="/members/orders" component={OrderList} />
              <Route path="/members/orders/:id" component={OrderDetail} />
            </Switch>
            :
            <Box>{translate('app.login.required')}</Box>
          }
        </Content>
      </Flex>
    </Container>
  )
}

export default withRouter(injectIntl(MemberLayout))