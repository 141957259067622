import React, { useState, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Flex from 'components/Flex'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { initialState, getModel } from 'models/signup'
import SessionContext from 'contexts/SessionContext'

function Signup({ intl, open, onClose }) {
  const session = useContext(SessionContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session })
  return (
    <Modal
      title={translate('signup.title')}
      width="250px"
      open={open}
      onClose={onClose}
    >
      <Flex height="100%" justifyContent="center" alignItems="center">
        <form onSubmit={model.handleSubmit({ onClose })} style={{ width: '100%' }}>
          {model.form.getUsername()}
          {model.form.getPassword()}
          {model.form.getPasswordConfirm()}
          <Flex justifyContent="center" alignItems="center" mt={3} mb={2}>
            <Button type="submit">
              {translate('signup.btn.signup')}
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  )
}

export default injectIntl(Signup)