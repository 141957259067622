import React from 'react'
import styled, { keyframes } from 'styled-components'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const loading = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

const Wrapper = styled(Flex)`
  opacity: 0;
  animation: ${fadeIn} 0.25s forwards;
  animation-delay: 0.25s;
`

const Dot = styled(Box).attrs({
  position: 'absolute',
  width: '13px',
  height: '13px',
  bg: 'dark.0',
})`
  top: ${props => props.top};
  left: ${props => props.left};
  border-radius: 50%;
  animation: ${loading} 1.2s linear infinite;
  animation-delay: ${props => props.delay};
`

export default ({ ...rest }) => (
  <Wrapper width={1} height={200} justifyContent="center" alignItems="center" {...rest}>
    <Box position="relative" width={64} height={64}>
      <Dot top="6px" left="6px" delay="0s" />
      <Dot top="6px" left="26px" delay="-0.4s" />
      <Dot top="6px" left="45px" delay="-0.8s" />
      <Dot top="26px" left="6px" delay="-0.4s" />
      <Dot top="26px" left="26px" delay="-0.8s" />
      <Dot top="26px" left="45px" delay="-1.2s" />
      <Dot top="45px" left="6px" delay="-0.8s" />
      <Dot top="45px" left="26px" delay="-1.2s" />
      <Dot top="45px" left="45px" delay="-1.6s" />
    </Box>
    <Text color="dark.0" fontSize={4} ml={3}>LOADING...</Text>
  </Wrapper>
)