import * as type from 'constants/actionType'

export const initialState = {
  sidebar: false,
  cartDrawer: false,
  cartItems: [],
  token: getLocalJson('token') || { accessToken: '', refreshToken: '' },
  user: {},
  orders: [],
  breadcrumbs: [],
  categories: [],
}

export function reducer(state, action) {
  switch (action.type) {
    case type.BREADCRUMB_SET:
      return { ...state, breadcrumbs: action.items }
    case type.CATEGORY_SET:
      return { ...state, categories: action.items }
    case type.LOGOUT:
      return { ...state, token: { accessToken: '', refreshToken: '' } }
    case type.SIDEBAR_TOGGLE:
      return { ...state, sidebar: !state.sidebar }
    case type.CARTDRAWER_TOGGLE:
      return { ...state, cartDrawer: !state.cartDrawer }
    case type.CART_ADD:
      return { ...state, cartItems: addItem(state.cartItems, action.item) }
    case type.CART_EDIT:
      return { ...state, cartItems: replaceItem(state.cartItems, action.item) }
    case type.CART_REMOVE:
      return { ...state, cartItems: removeItem(state.cartItems, action.id) }
    case type.CART_CLEAR:
      return { ...state, cartItems: [] }
    case type.TOKEN_SET:
      setLocalJson('token', action.token)
      return { ...state, token: action.token }
    case type.TOKEN_REMOVE:
      return { ...state, token: initialState.token }
    default:
      return state
  }
}

function getLocalJson(key) {
  const val = localStorage.getItem(key)
  if (!val) { return null }
  return JSON.parse(val)
}

function setLocalJson(key, val) {
  localStorage.setItem(key, JSON.stringify(val))
}

function addItem(list, data) {
  const result = [...list]
  const idx = list.findIndex(item => item.id === data.id)
  if (idx === -1) {
    result.push(data)
    return result
  }

  result.splice(idx, 1, data)
  return result
}

function replaceItem(list, data) {
  const idx = list.findIndex(item => item.id === data.id)
  if (idx === -1) { return list }

  const result = [...list]
  result.splice(idx, 1, data)
  return result
}

function removeItem(list, id) {
  const idx = list.findIndex(item => item.id === id)
  if (idx === -1) { return list }

  const result = [...list]
  result.splice(idx, 1)
  return result
}