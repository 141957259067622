export const ALERT_ADD = 'ALERT_ADD'
export const ALERT_REMOVE = 'ALERT_REMOVE'
export const LOADING_SHOW = 'LOADING_SHOW'
export const LOADING_HIDE = 'LOADING_HIDE'
export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE'
export const CARTDRAWER_TOGGLE = 'CARTDRAWER_TOGGLE'
export const CART_ADD = 'CART_ADD'
export const CART_EDIT = 'CART_EDIT'
export const CART_REMOVE = 'CART_REMOVE'
export const CART_CLEAR = 'CART_CLEAR'
export const LOGIN_DLG_TOGGLE = 'LOGIN_DLG_TOGGLE'
export const SIGNUP_DLG_TOGGLE = 'SIGNUP_DLG_TOGGLE'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const TOKEN_SET = 'TOKEN_SET'
export const TOKEN_REMOVE = 'TOKEN_REMOVE'
export const BREADCRUMB_SET = 'BREADCRUMB_SET'
export const CATEGORY_SET = 'CATEGORY_SET'