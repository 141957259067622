import React, { useEffect, useContext } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Link from 'components/Link'
import Container from 'components/Container'
import SocialList from 'components/SocialList'
import { getModel } from 'models/category'
import AppContext from 'contexts/AppContext'
import { CATEGORY_SET } from 'constants/actionType'
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'

const Block = styled(Flex).attrs({
  px: 4,
  pb: 5,
  flexDirection: 'column',
  flex: ['100%', '50%', 1, 1],
})`
  border: 1px;
  border-right-width: ${(props) => (props.last ? 0 : '1px')};
  border-left-width: ${(props) => (props.first ? 0 : '1px')};
  border-style: solid;
  border-color: ${(props) => props.theme.colors.dark[1]};
  color: ${(props) => props.theme.colors.light[3]};
`

const BlockTitle = styled(Text).attrs({
  as: 'h3',
  textStyle: 'header',
})`
  color: ${(props) => props.theme.colors.light[0]};
`

const BlockList = styled(Box).attrs({
  as: 'ul',
  m: 0,
  px: 3,
})`
  list-style-type: circle;
`

const BlockItem = styled(Box).attrs({
  as: 'li',
})`
  transition: color 0.2s ease-out, margin-left 0.2s ease-out;
  &:hover {
    color: ${(props) => props.theme.colors.light[0]};
    margin-left: ${(props) => props.theme.space[2]}px;
  }
`

const Copyright = styled(Flex).attrs({
  alignItems: 'center',
})``

const Footer = ({ intl }) => {
  const { state, dispatch } = useContext(AppContext)
  const categories = state.categories
  const translate = getTranslate(intl)
  const model = getModel({
    state: categories,
    setState: (items) => dispatch({ type: CATEGORY_SET, items }),
  })

  useEffect(() => {
    model.handleFetch()
  }, [])

  return (
    <Container>
      <Flex flexWrap="wrap">
        <Block first>
          <BlockTitle>About us</BlockTitle>
          <Box mt={0} mb={3} color="dark.0">
            <Text mb={3}>德國達麗集團 新穎的傳統思維</Text>
            <Text>
              德國達麗集團擁有超過170年的歷史，成功結合了傳統價值與創新思維，以提供效率與未來性兼具的服務為目標而努力著。堅持德國原裝進口，榮獲歐盟環保清潔用品認證
              (The A.I.S.E Charter for Sustainable Cleaning)
              殊榮，視永續發展為最重要的企業責任與信念，品質卓倫。
            </Text>
          </Box>
          {/* <Link>more</Link> */}
        </Block>
        <Block>
          <BlockTitle>Catalog products</BlockTitle>
          <BlockList color="dark.0">
            {categories.length &&
              categories.map((item) => (
                <BlockItem key={item.id}>
                  <Link url={`/products?category=${item.id}`}>{item.name}</Link>
                </BlockItem>
              ))}
          </BlockList>
        </Block>
        <Block>
          <BlockTitle>Information</BlockTitle>
          <BlockList color="dark.0">
            <BlockItem>
              <Link url="/info/environment">
                {translate('sidebar.environment')}
              </Link>
            </BlockItem>
            <BlockItem>
              <Link url="/info/safty">{translate('sidebar.safty')}</Link>
            </BlockItem>
            <BlockItem>
              <Link url="/info/quality">{translate('sidebar.quality')}</Link>
            </BlockItem>
            <BlockItem>
              <Link url="/info/laundry">{translate('sidebar.laundry')}</Link>
            </BlockItem>
          </BlockList>
        </Block>
        <Block last>
          <BlockTitle>Contacts</BlockTitle>
          <Flex mb={3} color="dark.0">
            <FaPhone />
            <Text ml={3}>(02) 8913-1001</Text>
          </Flex>
          <Flex mb={3} color="dark.0">
            <FaEnvelope />
            <Link ml={3}>sales@vic-apply.com.tw</Link>
          </Flex>
          <Flex mb={3} color="dark.0">
            <FaMapMarkerAlt />
            <Box as="address" ml={3}>
              新北市新店區北新路3段207-2號8樓
            </Box>
          </Flex>
        </Block>
      </Flex>

      <Flex px={4} py={3} alignItems="center" flexWrap="wrap" color="dark.0">
        <Copyright flex={['100%', 1]}>
          Copyright © 2019
          <Link
            ml={2}
            color="light.3"
            onClick={() => {
              window.location.href = 'http://vic-apply.com.tw'
            }}
          >
            慎康企業股份有限公司
          </Link>
        </Copyright>
        <Flex
          justifyContent={['flex-start', 'flex-end']}
          alignItems="center"
          flex={['100%', 1]}
        >
          <Text mr={3}>Let’s connect:</Text>
          <SocialList mt={[3, 0]} />
        </Flex>
      </Flex>
    </Container>
  )
}

export default injectIntl(Footer)
