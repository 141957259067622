import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import Text from 'components/Text'

const Anchor = styled(Text)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

Anchor.defaultProps = {
  as: 'a',
}

const Link = ({ history, url, children, ...rest }) => (
  <Anchor onClick={() => history.push(url)} {...rest}>{children}</Anchor>
)

Link.defaultProps = {
  url: '/'
}

export default withRouter(Link)