import React, { useReducer } from 'react'
import ReactDOM from 'react-dom'
import createHistory from 'history/createBrowserHistory'
import { Router, Route, Switch } from 'react-router-dom'
import { addLocaleData, IntlProvider } from 'react-intl'
import zhLocaleData from 'react-intl/locale-data/zh'
import zjTW from 'messages/zh-TW'
import { ThemeProvider } from 'styled-components'
import theme from 'configs/theme'
import LoadingBar from 'components/LoadingBar'
import Notification from 'components/Notification'
import MainLayout from 'layouts/MainLayout'
import SessionContext from 'contexts/SessionContext'
import { initialState, reducer } from 'reducers/root'

const rootDiv = document.getElementById('root')
const history = createHistory()
history.listen(() => { rootDiv.scrollTop = 0 })

addLocaleData(zhLocaleData)

function Root() {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <IntlProvider locale="zh" messages={zjTW}>
      <ThemeProvider theme={theme}>
        <SessionContext.Provider value={{ state, dispatch }}>
          <Router history={history}>
            <Switch>
              <Route path="/" component={MainLayout} />
            </Switch>
          </Router>
          <LoadingBar loading={state.loading} />
          <Notification values={state.alerts} />
        </SessionContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  )
}

ReactDOM.render(<Root />, rootDiv)
