import React, { useContext } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import { CARTDRAWER_TOGGLE, CART_REMOVE } from 'constants/actionType'
import Flex from 'components/Flex'
import Box from 'components/Box'
import Text from 'components/Text'
import Image from 'components/Image'
import Divider from 'components/Divider'
import Overlay from 'components/Overlay'
import Button from 'components/Button'
import { MdDelete } from 'react-icons/md'

const url = process.env.REACT_APP_STATIC_URL
const drawerWidth = '293px'

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
  width: ${props => props.open ? drawerWidth : 0};
  height: 100%;
  background-color: ${props => props.theme.colors.light[0]};
  overflow-x: hidden;
  transition: width 0.3s;
`

const Title = styled(Text)`
  overflow: hidden;
  cursor: pointer;
`

const CartDrawer = ({ intl, history, open, ...rest }) => {
  const { state, dispatch } = useContext(AppContext)
  const translate = getTranslate(intl)
  const goto = url => {
    history.push(url)
    dispatch({ type: CARTDRAWER_TOGGLE })
  }
  return (
    <>
      <Overlay open={open} onClick={() => dispatch({ type: CARTDRAWER_TOGGLE })} />
      <Wrapper open={open} {...rest}>
        <Box width={drawerWidth}>
          <Text p={3} color="dark.0" fontSize={2}>{translate('cart.title')}</Text>
          <Divider mx={3} />
          <Box>
            {state.cartItems.map(item => {
              const { id, product } = item
              return (
                <Flex key={id} alignItems="center" p={3}>
                  <Image
                    src={url + product.primaryImage}
                    width="48px"
                    height="48px"
                    size="contain"
                    onClick={() => goto(`/products/${id}`)}
                  />
                  <Flex flex={1} mx={3} flexDirection="column">
                    <Title onClick={() => goto(`/products/${id}`)}>{product.name}</Title>
                    <Text fontSize={1} color="light.4">NT.{product.price}</Text>
                  </Flex>
                  <Button variant="plain" onClick={() => dispatch({ type: CART_REMOVE, id })}>
                    <MdDelete />
                  </Button>
                </Flex>
              )
            })}
          </Box>
          <Divider mx={3} />
          <Flex justifyContent="center" mt={3}>
            <Button onClick={() => goto('/checkouts')}>
              {translate('cart.btn.viewDetail')}
            </Button>
          </Flex>
        </Box>
      </Wrapper>
    </>
  )
}

export default injectIntl(withRouter(CartDrawer))