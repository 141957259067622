import React from 'react'
import { injectIntl } from 'react-intl'
import Flex from 'components/Flex'
import Image from 'components/Image'
import SidebarMenu from 'components/SidebarMenu'
import CartIcon from 'components/CartIcon'
import Container from 'components/Container'

const url = process.env.REACT_APP_STATIC_URL

const Header = ({ intl, isLogin, onLoginOpen, onSignupOpen, onLogout }) => {
  const isHome = window.location.pathname === '/home'

  return (
    <Container>
      <Flex as="header">
        <Flex mr="auto">
          <SidebarMenu py={3} pl={[2, 0]} />
        </Flex>
        <Flex>
          <CartIcon mx={2} py={3} />
        </Flex>
      </Flex>
      {!isHome &&
        <Image
          src={url + 'misc/bg-03.jpg'}
          height={185}
          overlay={{ opacity: 0.5 }}
        />}
    </Container>
  )
}

export default injectIntl(Header)