import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Box from 'components/Box'
import Link from 'components/Link'

const fade = keyframes`
  from {opacity: .4} 
  to {opacity: 1}
`

const Slide = styled(Box).attrs({
  position: 'absolute',
})`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: ${props => props.hidden ? 'none' : 'block'};
  animation: ${fade} 2s;
`

const Nav = styled(Link).attrs({
  p: 3,
  fontSize: 3,
  color: 'light.0',
})`
  position: absolute;
  ${props => props.left && 'left: 0'};
  ${props => props.right && 'right: 0'};
  top: 50%;
  width: auto;
  margin-top: -22px;
  font-weight: bold;
  opacity: 0.6;
  transition: background-color 0.6s ease;
  user-select: none;
  &:hover {
    background-color: rgba(0,0,0,0.6);
  }
`

// const Dot = styled(Flex)``

export default ({ slides, ratio, ...rest }) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const goto = index => {
    setActiveIdx((index + slides.length) % slides.length)
  }

  return (
    <Box position="relative" pt={`${ratio * 100}%`} {...rest}>
      {slides.map((slide, index) => (
        <Slide key={index} hidden={index !== activeIdx}>
          {slide}
        </Slide>
      ))}

      <Nav left onClick={() => goto(activeIdx + 1)}>&#10094;</Nav>
      <Nav right onClick={() => goto(activeIdx - 1)}>&#10095;</Nav>

      {/* <Flex>
        {slides.map((_, index) => (
          <Dot key={index} onClick={() => goto(index)} />
        ))}
      </Flex> */}
    </Box>
  )
}