import styled from 'styled-components'

const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.space[3]}px;
  margin: 0;
  border: 1px solid ${({ theme: { colors }, hasError }) => hasError ? colors.error[1] : colors.light[3]};
  border-radius: ${({ theme }) => theme.space[1]}px;
  transition: border-color 0.2s linear;
  will-change: border-color;
  &:focus {
    outline: none;
    border-color: ${({ theme: { colors }, hasError }) => hasError ? colors.error[1] : colors.accent[1]};
  }
`

export default TextArea