import React from 'react'
import styled from 'styled-components'
import Text from 'components/Text'
import Flex from 'components/Flex'
import Button from 'components/Button'

const InputWrapper = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
})`
  border-top: 1px solid ${props => props.theme.colors.light[3]};
  border-bottom: 1px solid ${props => props.theme.colors.light[3]};
`

export default ({ value, onChange }) => {
  return (
    <Flex alignItems="center">
      <Button variant="plain" width={40} height={40} px={2} onClick={() => onChange(value - 1)}>-</Button>
      <InputWrapper>
        <Text>{value}</Text>
      </InputWrapper>
      <Button variant="plain" width={40} height={40} px={2} onClick={() => onChange(value + 1)}>+</Button>
    </Flex>
  )
}