import React from 'react'
import { request } from 'utilities/graphql'
import { initializeState, handleFieldChange, validateForm } from 'utilities/form'
import { BREADCRUMB_SET, ALERT_ADD } from 'constants/actionType'
import FormField from 'components/FormField'
import TextInput from 'components/TextInput'

export const initialState = initializeState({
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
})

const validation = {
  password: [{ type: 'required', message: 'error.required' }],
  newPassword: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
  ],
  newPasswordConfirm: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    { type: 'fieldEqual', name: 'newPassword', message: 'account.error.passNotMatch' },
  ],
}

export function getModel({ state, setState, translate, session, app }) {
  return {
    form: getForm({ state, setState, translate }),
    handleSubmit: handleSubmit({ state, setState, session, app }),
    setBreadcrumb: setBreadcrumb({ app, translate }),
  }
}

function getForm({ state, setState, translate }) {
  return {
    getPassword: () => (
      <FormField errMsg={translate(state.error.password)}>
        <TextInput
          type="password"
          placeholder={translate('account.field.password')}
          value={state.value.password}
          onChange={handleFieldChange(state, setState, 'password', validation.password)}
          hasError={!!state.error.password}
        />
      </FormField>
    ),
    getNewPassword: () => (
      <FormField errMsg={translate(state.error.newPassword)}>
        <TextInput
          type="password"
          placeholder={translate('account.field.newPassword')}
          value={state.value.newPassword}
          onChange={handleFieldChange(state, setState, 'newPassword', validation.newPassword)}
          hasError={!!state.error.newPassword}
        />
      </FormField>
    ),
    getNewPasswordConfirm: () => (
      <FormField errMsg={translate(state.error.newPasswordConfirm)}>
        <TextInput
          type="password"
          placeholder={translate('account.field.newPasswordConfirm')}
          value={state.value.newPasswordConfirm}
          onChange={handleFieldChange(state, setState, 'newPasswordConfirm', validation.newPasswordConfirm)}
          hasError={!!state.error.newPasswordConfirm}
        />
      </FormField>
    )
  }
}

function handleSubmit({ state, setState, session, app }) {
  return async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) { return }

    const { password, newPassword } = state.value
    const variables = { password, newPassword }
    const query = `
      mutation EditUserPass($password: String!, $newPassword: String!) {
        editUserPass(password: $password, newPassword: $newPassword) 
      }
    `
    const [ok] = await request({ query, variables }, { session, app })
    if (!ok) { return }

    setState(initialState)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'account.success.editUserPass' }
    })
  }
}

function setBreadcrumb({ app, translate }) {
  return () => {
    const items = [
      { label: 'Home', url: '/' },
      { label: translate('account.title') },
    ]

    app.dispatch({ type: BREADCRUMB_SET, items })
  }
}