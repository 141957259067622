import styled from 'styled-components'
import { fontWeight, fontSize, buttonStyle } from 'styled-system'
import Box from 'components/Box'

const Button = styled(Box)`
  appearance: none;
  display: inline-flex;
  outline: none;
  justify-content: center;
  align-items: center;
  line-height: inherit;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0;
  user-select: none;
  cursor: pointer;
  ${fontWeight}
  ${fontSize}
  ${buttonStyle}
`

Button.propTypes = {
  ...fontWeight.propTypes,
  ...fontSize.propTypes,
  ...buttonStyle.propTypes,
}

Button.defaultProps = {
  as: 'button',
  fontSize: 'inherit',
  fontWeight: 300,
  m: 0,
  px: 3,
  py: 2,
  variant: 'default',
}

export default Button