import { request } from 'utilities/graphql'
import { BREADCRUMB_SET } from 'constants/actionType'

export const initialState = []

export function getModel({ state, setState, session, app, translate }) {
  return {
    handleFetch: handleFetch({ state, setState, session }),
    setBreadcrumb: setBreadcrumb({ app, translate }),
  }
}

function handleFetch({ state, setState, session }) {
  return async () => {
    const query = `
      query {
        listProducts {
          id
          categoryId
          name
          primaryImage
          price
        }
      }
    `
    const [ok, data] = await request({ query }, { session })
    if (!ok) { return }

    setState(data.listProducts)
  }
}

function setBreadcrumb({ app, translate }) {
  return categoryId => {
    const category = app.state.categories.find(item => item.id === categoryId)
    const items = [{ label: 'Home', url: '/' }]

    if (category) {
      items.push({ label: translate('category.title'), url: '/products' })
      items.push({ label: category.name })
    } else {
      items.push({ label: translate('category.title') })
    }

    app.dispatch({ type: BREADCRUMB_SET, items })
  }
}