import React from 'react'
import styled from 'styled-components'
import Box from 'components/Box'
import Text from 'components/Text'

const Wrapper = styled(Box)`
  overflow-x: auto;
`

const Table = styled(Box).attrs({
  as: 'table',
  width: 1,
})`
  border-collapse: collapse;
`

const Row = styled.tr`
  &:nth-child(odd) {
    background: ${props => props.bg ? props.bg : props.theme.colors.light[0]};
  }
`

const HeaderCell = styled(Box).attrs({
  as: 'th',
  py: 3,
  px: 2,
})`
  border-bottom: none;
  text-align: left;
  font-weight: bold;
  white-space: nowrap;
`

const Cell = styled(Box).attrs({
  as: 'td',
  py: 3,
  px: 2,
})`
  border-top: 1px solid ${props => props.theme.colors.light[3]};
`

export default ({ columns, rows, footer, ...rest }) => {
  const renderCell = (column, row) => {
    if (column.render) { return column.render(row) }
    return <Text>{column.format ? column.format(row) : row[column.id]}</Text>
  }
  
  return (
    <Wrapper {...rest}>
      <Table>
        <thead>
          <Row bg="light.1">
            {columns.map(column => (
              <HeaderCell key={column.id}>{column.label}</HeaderCell>
            ))}
          </Row>
        </thead>
        <tbody>
          {rows && rows.map((row, index) => (
            <Row key={index}>
              {columns.map(column => (
                <Cell key={column.id}>{renderCell(column, row)}</Cell>
              ))}
            </Row>
          ))}
        </tbody>
        {footer && <tfoot>{footer}</tfoot>}
      </Table>
    </Wrapper>
  )
}