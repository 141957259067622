import React from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import { MdShoppingCart } from 'react-icons/md'

export default function ({ ...rest }) {
  return (
    <Link
      {...rest}
      color='#efa96b'
      fontSize={3}
      onClick={() => {
        window.location.href = 'https://shop.vic-apply.com.tw'
      }}
    >
      <MdShoppingCart />
      <Text fontSize={2} ml={1}>
        線上購物
      </Text>
    </Link>
  )
}
