import { LOADING_SHOW, LOADING_HIDE, ALERT_ADD, TOKEN_SET } from 'constants/actionType'

const url = process.env.REACT_APP_API_URL

export async function request({ query, variables }, { session, app, token } = {}) {
  try {
    if (session) {
      session.dispatch({ type: LOADING_SHOW })
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }

    if (!token) {
      token = app && app.state.token
    }

    if (token && token.accessToken) {
      headers['Authorization'] = `Bearer ${token.accessToken}`
    }

    const req = {
      method: 'POST',
      headers,
      body: JSON.stringify({ query, variables })
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('API Request:', req)
    }

    const resp = await fetch(url, req)
    const respJson = await resp.json()
    if (process.env.NODE_ENV !== 'production') {
      console.log('API Response:', respJson)
    }
    
    return handleResp(respJson, { query, variables }, { session, app })
  } catch (e) {
    return handleResp(e, { query, variables }, { session, app })
  } finally {
    if (session) {
      session.dispatch({ type: LOADING_HIDE })
    }
  }
}

async function handleResp(resp, { query, variables }, { session, app }) {
  if (!resp.errors) {
    return [true, resp.data]
  }

  resp.errors.forEach(err => console.error(err.message))

  let token = app && app.state.token
  const message = resp.errors[0].message

  if (message === 'error.auth.tokenNotFound' && session) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message: 'app.login.required' }
    })
    return [false]
  }

  if (message === 'jwt expired') {
    token = await refreshToken(token, session, app)
    app.dispatch({ type: TOKEN_SET, token })
    return request({ query, variables }, { session, app, token })
  }

  if (session) {
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'error', message }
    })
  }
  return [false, resp.errors]
}

async function refreshToken(token, session, app) {
  const variables = { refreshToken: token.refreshToken }
  const query = `
    mutation RefreshToken($refreshToken: String!) {
      refreshToken(refreshToken: $refreshToken) {
        accessToken
        refreshToken
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) {
    return { accessToken: '', refreshToken: '' }
  }

  return data.refreshToken
}