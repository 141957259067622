import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Link from 'components/Link'

const fade = keyframes`
  from {opacity: .4} 
  to {opacity: 1}
`

const Slide = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
  p: [3, 5],
  height: '100%'
})`
  display: ${props => props.hidden ? 'none' : 'block'};
  animation: ${fade} 2s;
`

const Nav = styled(Link).attrs({
  p: 3,
  fontSize: 3,
  color: 'light.0',
})`
  position: absolute;
  ${props => props.left && 'left: 0'};
  ${props => props.right && 'right: 0'};
  top: 50%;
  width: auto;
  margin-top: -22px;
  font-weight: bold;
  transition: background-color 0.6s ease;
  user-select: none;
  &:hover {
    background-color: rgba(0,0,0,0.4);
  }
`

const ThumbnailWrapper = styled(Box).attrs({
  mx: -1,
})`
  overflow-x: auto;
  white-space: nowrap;
`

const Thumbnail = styled(Box).attrs({
  width: '82px',
  height: '82px',
  m: 1,
  p: 2,
})`
  display: inline-block;
  cursor: pointer;
`

export default ({ slides, ...rest }) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const goto = index => {
    setActiveIdx((index + slides.length) % slides.length)
  }

  return (
    <>
      <Box position="relative" {...rest}>
        {slides.map((slide, index) => (
          <Slide key={index} hidden={index !== activeIdx}>
            {slide}
          </Slide>
        ))}

        <Nav left onClick={() => goto(activeIdx + 1)}>&#10094;</Nav>
        <Nav right onClick={() => goto(activeIdx - 1)}>&#10095;</Nav>
      </Box>
      <ThumbnailWrapper>
        {slides.map((slide, index) => (
          <Thumbnail key={index} variant="light" onClick={() => goto(index)}>
            {slide}
          </Thumbnail>
        ))}
      </ThumbnailWrapper>
    </>
  )
}