export function getTranslate(intl) {
  return data => {
    if (!data) { return }

    if (typeof data === 'string') {
      return intl.formatMessage({ id: data })
    }

    return intl.formatMessage({ id: data[0] }, data[1])
  }
}