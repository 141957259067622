import { request } from 'utilities/graphql'

export const initialState = []

export function getModel({ state, setState }) {
  return {
    handleFetch: handleFetch({ state, setState })
  }
}

function handleFetch({ state, setState }) {
  return async () => {
    const query = `
      query ListCategories {
        listCategories {
          id
          name
        }
      }
    `
    const [ok, data] = await request({ query })
    if (!ok) { return }
    
    setState(data.listCategories)
  }
}