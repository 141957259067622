import { request } from 'utilities/graphql'
import { STATUS_PENDING } from 'constants/status'

const initialState = STATUS_PENDING

function getModel({ state, setState, session }) {
  return {
    activateMember: activateMember({ state, setState, session })
  }
}

function activateMember({ state, setState, session }) {
  return async ({ id, code }) => {
    const variables = { id, code }
    const query = `
      mutation ActivateUser($id: ID!, $code: String!) {
        activateUser(id: $id, code: $code) {
          status
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) { return }
    setState(data.activateUser.status)
  }
}

export { initialState, getModel }