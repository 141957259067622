import React from 'react'
import styled from 'styled-components'
import Box from 'components/Box'
import Flex from 'components/Flex'

const Cotent = styled(Box).attrs({
  width: ['100%', '768px', '1200px', '1600px'],
})`
  position: relative;
  color: ${props => props.theme.colors.light[0]};
`

export default ({ children }) => (
  <Flex justifyContent="center">
    <Cotent>{children}</Cotent>
  </Flex>
)