import React, { useState, useEffect, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import { initialState, getModel } from 'models/orderDetail'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Flex from 'components/Flex'
import Image from 'components/Image'
import Text from 'components/Text'
import Table from 'components/Table'
import LoadingIcon from 'components/LoadingIcon'

const url = process.env.REACT_APP_STATIC_URL

function OrderDetail({ intl, match }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session, app })
  const orderId = match.params.id

  useEffect(() => {
    document.title = translate('orderDetail.title')
    model.handleFetch(orderId)
    model.setBreadcrumb()
  }, [orderId])
  
  if (!state.id) {
    return <LoadingIcon />
  }

  return (
    <Table
      width={1}
      bg="light.0"
      columns={[
        {
          id: 'product',
          label: translate('cart.table.product'),
          render: row => {
            return (
              <Flex alignItems="center">
                <Image src={url + row.image} width="64px" height="64px" size="contain" />
                <Text mx={3}>{row.name}</Text>
              </Flex>
            )
          }
        },
        {
          id: 'unitPrice',
          label: translate('cart.table.unitPrice'),
          render: row => <Text>NT.{row.price}</Text>
        },
        {
          id: 'quantity',
          label: translate('cart.table.quantity')
        },
        {
          id: 'price',
          label: translate('cart.table.price'),
          render: row => {
            const { price, quantity } = row
            return <Text>NT.{price * quantity}</Text>
          }
        },
      ]}
      rows={state.products}
    />
  )
}

export default injectIntl(OrderDetail)