import React, { useContext } from 'react'
import AppContext from 'contexts/AppContext'
import Text from 'components/Text'
import Link from 'components/Link'
import { SIDEBAR_TOGGLE } from 'constants/actionType'
import { MdMenu } from 'react-icons/md'

export default function ({ ...rest }) {
  const { dispatch } = useContext(AppContext)
  return (
    <Link {...rest} onClick={() => dispatch({ type: SIDEBAR_TOGGLE })}>
      <Text fontSize={4} mr={2}><MdMenu /></Text>
      <Text fontSize={3}>MENU</Text>
    </Link>
  )
}