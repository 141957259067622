import { request } from 'utilities/graphql'
import { BREADCRUMB_SET } from 'constants/actionType'

export const initialState = {}

export function getModel({ state, setState, translate, session, app }) {
  return {
    handleFetch: handleFetch({ state, setState, session, app }),
    setBreadcrumb: setBreadcrumb({ app, translate }),
  }
}

function handleFetch({ setState, session, app }) {
  return async id => {
    const variables = { id }
    const query = `
      query ($id: ID!) {
        createTicket(id: $id) {
          id
          products {
            productId
            name
            price
            quantity
            image
          }
          transDate
          totalAmount
          status
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session, app })
    if (!ok) { return }
    
    setState(data.createTicket)
  }
}

function setBreadcrumb({ app, translate }) {
  return () => {
    const items = [
      { label: 'Home', url: '/' },
      { label: translate('orderList.title'), url: '/members/orders' },
      { label: translate('orderDetail.title') },
    ]

    app.dispatch({ type: BREADCRUMB_SET, items })
  }
}