import styled from 'styled-components'
import Box from 'components/Box'

export default styled(Box)`
  position: fixed;
  display: ${props => props.open ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 100;
  cursor: pointer;
`