import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import AppContext from 'contexts/AppContext'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'
import Category from 'components/Category'
import ProductList from 'views/ProductList'
import ProductDetail from 'views/ProductDetail'

function ProductLayout() {
  const { state } = useContext(AppContext)
  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb items={state.breadcrumbs} />
      </Box>
      <Flex bg="light.1" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', 1, 1]} bg="light.1" mr={[0, 4]} mb={4}>
          <Category width={1} />
        </Box>
        <Flex flex={['100%', 2, 4]} flexWrap="wrap">
          <Switch>
            <Route exact path="/products" component={ProductList} />
            <Route path="/products/:id" component={ProductDetail} />
          </Switch>
        </Flex>
      </Flex>
    </Container>
  )
}

export default ProductLayout