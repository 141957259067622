import styled from 'styled-components'
import { flexWrap, flexDirection, alignItems, alignContent, justifyContent } from 'styled-system'
import Box from 'components/Box'

const Flex = styled(Box)`
  display: flex;
  ${flexWrap}
  ${flexDirection}
  ${justifyContent}
  ${alignItems}
  ${alignContent}
`

Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...justifyContent.propTypes,
  ...alignItems.propTypes,
  ...alignContent.propTypes,
}

export default Flex