import React, { useState, useEffect, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import { initialState, getModel } from 'models/account'
import SessionContext from 'contexts/SessionContext'
import AppContext from 'contexts/AppContext'
import Button from 'components/Button'
import Box from 'components/Box'

function Account({ intl }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session, app })

  useEffect(() => {
    document.title = translate('account.title')
    model.setBreadcrumb()
  }, [])

  return (
    <Box width={1 / 3}>
      <form onSubmit={model.handleSubmit} style={{ width: '100%' }}>
        {model.form.getPassword()}
        {model.form.getNewPassword()}
        {model.form.getNewPasswordConfirm()}
        <Button type="submit">
          {translate('btn.submit')}
        </Button>
      </form>
    </Box>
  )
}

export default injectIntl(Account)