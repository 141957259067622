import React from 'react'
import styled from 'styled-components'
import Box from 'components/Box'

const Content = styled(Box).attrs({
  position: 'absolute',
})`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

export default ({ ratio, children, ...rest }) => (
  <Box
    position="relative"
    width={1}
    pt={`${ratio * 100}%`}
    {...rest}
  >
    <Content>{children}</Content>
  </Box>
)