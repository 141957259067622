import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'

export default ({ id, label, errMsg, children }) => (
  <Box mt={2} mb={2} py={1}>
    {label && <label for={id}>{label}</label>}
    {children}
    {errMsg && <Text colors="error">{errMsg}</Text>}
  </Box>
)