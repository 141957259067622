import React from 'react'
import styled from 'styled-components'
import Flex from 'components/Flex'
import Link from 'components/Link'
import Text from 'components/Text'

const Ul = styled(Flex).attrs({
  as: 'ul',
  p: [2, 4],
  m: 0,
  color: 'dark.0',
})`
  list-style: none;
  text-transform: uppercase;
`

const Li = styled(Flex).attrs({
  as: 'li',
  alignItems: 'center',
})`
  &+&:before {
    padding: 0 ${props => props.theme.space[2]}px;
    content: "/";
  }
`

export default ({ items, ...rest }) => {
  if (!items) { return null }
  return (
    <Ul {...rest}>
      {items.map((item, index) => (
        <Li key={index}>
          {item.url ?
            <Link url={item.url}>{item.label}</Link> :
            <Text color="light.4">{item.label}</Text>
          }
        </Li>
      ))}
    </Ul>
  )
}