import React from 'react'
import { request } from 'utilities/graphql'
import { initializeState, handleFieldChange, handleKeyPress, validateForm } from 'utilities/form'
import FormField from 'components/FormField'
import TextInput from 'components/TextInput'
import TextArea from 'components/TextArea'

export const initialState = initializeState({
  name: '',
  phone: '',
  address: '',
  memo: '',
})

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  phone: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 20, message: ['error.maxLength', { val: 25 }] },
  ],
  address: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 65, message: ['error.maxLength', { val: 65 }] },
  ],
  memo: [
    { type: 'maxLength', val: 500, message: ['error.maxLength', { val: 500 }] },
  ],
}

export function getModel({ state, setState, translate, session, app }) {
  return {
    form: getForm({ state, setState, translate }),
    createOrder: createOrder({ state, setState, session, app }),
    createTrade: createTrade({ state, session, app }),
  }
}

function getForm({ state, setState, translate }) {
  return {
    getName: () => (
      <FormField errMsg={translate(state.error.name)}>
        <TextInput
          placeholder={translate('checkout.field.name')}
          value={state.value.name}
          onChange={handleFieldChange(state, setState, 'name', validation.name)}
          hasError={!!state.error.name}
        />
      </FormField>
    ),
    getPhone: () => (
      <FormField errMsg={translate(state.error.phone)}>
        <TextInput
          placeholder={translate('checkout.field.phone')}
          value={state.value.phone}
          onKeyPress={handleKeyPress(state, setState, 'phone', /[0-9|\-|\\(|\\)]/)}
          onChange={handleFieldChange(state, setState, 'phone', validation.phone)}
          hasError={!!state.error.phone}
        />
      </FormField>
    ),
    getAddress: () => (
      <FormField errMsg={translate(state.error.address)}>
        <TextInput
          placeholder={translate('checkout.field.address')}
          value={state.value.address}
          onChange={handleFieldChange(state, setState, 'address', validation.address)}
          hasError={!!state.error.address}
        />
      </FormField>
    ),
    getMemo: () => (
      <FormField errMsg={translate(state.error.memo)}>
        <TextArea
          rows="4"
          placeholder={translate('checkout.field.memo')}
          value={state.value.memo}
          onChange={handleFieldChange(state, setState, 'memo', validation.memo)}
          hasError={!!state.error.memo}
        />
      </FormField>
    )
  }
}

function createOrder({ state, setState, session, app }) {
  return async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) { return }

    const cartItems = app.state.cartItems
    const products = cartItems.map(item => ({
      productId: item.id,
      name: item.product.name,
      price: item.product.price,
      quantity: item.quantity,
      image: item.product.primaryImage,
    }))
    const shipping = state.value
    const totalAmount = cartItems.reduce((total, item) => {
      total += item.product.price * item.quantity
      return total
    }, 0)
    const input = { products, totalAmount, shipping }
    const variables = { input }
    const query = `
      mutation CreateTicket($input: OrderInput!) {
        createOrder(input: $input) {
          id
          totalAmount
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session, app })
    if (!ok) { return }

    return data.createOrder
  }
}

function createTrade({ state, session, app }) {
  return async ({ orderId, totalAmount }) => {
    const cartItems = app.state.cartItems
    const itemName = cartItems.reduce((result, item) => {
      const { product, quantity } = item
      result += `${product.name}(NT.${product.price})x${quantity}#`
      return result
    }, '')
    const input = {
      channelType: 'CREDIT_CARD',
      orderId,
      totalAmount,
      tradeDesc: 'Dalli Store',
      itemName,
      shipping: state.value,
    }
    const variables = { input }
    const query = `
      mutation CreateWebpay($input: PaymentInput!) {
        createWebpay(input: $input) {
          merchantId
          payToken
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session, app })
    if (!ok) { return }

    return data.createWebpay
  }
}