import React, { useState, useReducer, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom'
import { initialState, reducer } from 'reducers/app'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import Footer from 'components/Footer'
import CartDrawer from 'components/CartDrawer'
import LoginDialog from 'views/LoginDialog'
import SignupDialog from 'views/SignupDialog'
import Home from 'views/Home'
import Activation from 'views/Activation'
import Checkout from 'views/Checkout'
import Payment from 'views/Payment'
import ProductLayout from 'layouts/ProductLayout'
import MemberLayout from 'layouts/MemberLayout'
import Environment from 'views/Environment'
import Safty from 'views/Safty'
import Quality from 'views/Quality'
import Laundry from 'views/Laundry'
import { LOGOUT, ALERT_ADD } from 'constants/actionType'

function MainLayout({ history }) {
  const session = useContext(SessionContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [loginOpen, setLoginOpen] = useState(false)
  const [signupOpen, setSignupOpen] = useState(false)
  const { token: { accessToken } } = state

  const logout = () => {
    dispatch({ type: LOGOUT })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'logout.success' }
    })
    history.push('/')
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Header
        isLogin={!!accessToken}
        onLogout={logout}
        onLoginOpen={() => setLoginOpen(true)}
        onSignupOpen={() => setSignupOpen(true)}
      />
      <Sidebar open={state.sidebar} />
      <CartDrawer open={state.cartDrawer} />
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/products" component={ProductLayout} />
        <Route path="/checkouts" component={Checkout} />
        <Route path="/payments/:merchantId/tokens/:token" component={Payment} />
        <Route path="/registrations/:id/codes/:code" component={Activation} />
        <Route path="/members" component={MemberLayout} />
        <Route path="/info/environment" component={Environment} />
        <Route path="/info/safty" component={Safty} />
        <Route path="/info/quality" component={Quality} />
        <Route path="/info/laundry" component={Laundry} />
        <Redirect from="/" to="/home" />
      </Switch>
      <Footer />
      <LoginDialog
        open={loginOpen}
        onClose={() => setLoginOpen(false)}
      />
      <SignupDialog
        open={signupOpen}
        onClose={() => setSignupOpen(false)}
      />
    </AppContext.Provider>
  )
}

export default withRouter(MainLayout)