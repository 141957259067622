import React, { useState, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Flex from 'components/Flex'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { initialState, getModel } from 'models/login'

function Login({ intl, open, onClose }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session, app })
  
  return (
    <Modal
      title={translate('login.title')}
      width="250px"
      open={open}
      onClose={onClose}
    >
      <Flex height="100%" justifyContent="center" alignItems="center">
        <form onSubmit={model.handleSubmit({ onClose })} style={{ width: '100%' }}>
          {model.form.getUsername()}
          {model.form.getPassword()}
          <Flex justifyContent="center" alignItems="center" mt={3} mb={2}>
            <Button type="submit">
              {translate('login.btn.login')}
            </Button>
          </Flex>
        </form>
      </Flex>
    </Modal>
  )
}

export default injectIntl(Login)