import React, { useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import styled from 'styled-components'
import Flex from 'components/Flex'
import Box from 'components/Box'
import SessionContext from 'contexts/SessionContext'
import { MdClose } from 'react-icons/md'
import { ALERT_REMOVE } from 'constants/actionType'

const Wrapper = styled(Box).attrs({
  width: ['80%', '20%'],
  ml: ['-40%', '-10%'],
})`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 50%;
  overflow: hidden;
  display: ${props => props.hidden ? 'none' : 'block'};
`

const Item = styled(Flex).attrs({
  alignItems: 'center',
})``

const Close = styled(Flex).attrs({
  as: 'a',
  fontSize: 4,
})`
  cursor: pointer;
`

function Notification({ intl, values }) {
  const { dispatch } = useContext(SessionContext)
  const translate = getTranslate(intl)
  return (
    <Wrapper m={3} hidden={values.length === 0}>
      {values.map((value, idx) => {
        setTimeout(() => dispatch({ type: ALERT_REMOVE, idx }), 3000)
        return (
          <Item key={idx} variant={value.type} m={3} p={3}>
            <Box flex={1}>{translate(value.message)}</Box>
            <Close onClick={() => dispatch({ type: ALERT_REMOVE, idx })}>
              <MdClose />
            </Close>
          </Item>
        )
      })}
    </Wrapper>
  )
}

export default injectIntl(Notification)