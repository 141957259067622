import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Image from 'components/Image'
import List from 'components/List'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'

const url = process.env.REACT_APP_STATIC_URL

function Laundry({ intl }) {
  const [state, setState] = useState(0)
  const translate = getTranslate(intl)

  useEffect(() => {
    document.title = translate('laundry.title')
  }, [])

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('laundry.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.0" px={[2, 4]} pb={4} flexWrap="wrap">
        <Box flex={['100%', '0%', 1]}>
          <Image
            src={url + 'misc/bg-07.jpg'}
            maxWidth={300}
            height={800}
          />
        </Box>
        <Box
          bg="light.0"
          flex={['100%', 1, 3]}
          p={[2, 3, 4]}
        >
          {showContent(state)}
        </Box>
        <Box
          bg="light.0"
          flex={['100%', 1, 1]}
          p={[2, 3, 4]}
        >
          <Text variant="dark" p={2} justifyContent="center">洗衣小百科分類</Text>
          <List
            colors="dark"
            items={[
              { id: 0, label: '洗衣小訣竅' },
              { id: 1, label: '特殊纖維應如何照顧' },
              { id: 2, label: '洗衣標示符號說明' },
              { id: 3, label: '去漬小撇步' },
            ]}
            onItemClick={item => setState(item.id)}
          />
        </Box>
      </Flex>
    </Container>
  )
}

function showContent(tab) {
  switch (tab) {
    case 1:
      return (
        <>
          <Text as="h2">特殊纖維應如何照顧</Text>
          <Text as="p">
            特殊的纖維，需要您精心的照顧。!
          </Text>
          <Text as="h3">
            給現代新纖維最理想的呵護與照顧
          </Text>
          <Text as="p">
            dalli特殊纖維洗衣精質地絲滑細緻，針對如嫘縈(viscose)，彈性人造纖維(Spandex)，超細纖維(microfiber)，聚脂纖維(polyester)，彈性纖維(stretch)，混紡面料以及透氣運動衣、休閒衫等都非常合適。請您務必參照衣物上的洗衣標示來清洗此類衣物。此外，達麗建議您，此類衣物不建議使用衣物柔軟精。想洗淨特殊纖維的衣物，dalli特殊纖維洗衣精就是您最佳的選擇。
          </Text>
          <Text as="h3">
            深色衣物的專用洗衣精
          </Text>
          <Text as="p">
            dalli深色衣物洗衣精為您悉心呵護需要謹慎照顧的黑色、深色衣物。dalli深色衣物洗衣精內含獨特的護色因子，讓您的深色衣物常保如新，一次又一次的清洗過後，色澤依舊美麗。
          </Text>
          <Text as="h3">
            毛料和絲質等高級面料專用的洗衣精
          </Text>
          <Text as="p">
            您一定要使用dalli毛料絲絨專用洗衣精來照顧您的精緻毛料與絲質衣物。此款洗衣精針對毛料與絲質衣物精心設計，不含酵素，pH值中性，能溫和清潔並有效保護其細緻纖維，是最適合毛料與絲質纖維的洗衣精。
          </Text>
          <Text as="p">
            清洗毛料衣物時也請您注意下列事項，將能感受到dalli毛料絲絨專用洗衣精帶給毛料衣物的舒適觸感!
          </Text>
          <ul>
            <li>毛料衣物切忌使用高溫的水洗滌，另外像是又刷又拉扯的清洗方式也是絕對禁止的。</li>
            <li>毛料衣物不應在水中浸泡過久。</li>
            <li>不可使用烘乾機烘乾毛料衣物。</li>
            <li>注意衣物上的標示說明。</li>
          </ul>
          <Text as="h3">
            過敏人士與敏感肌膚專用的洗衣精
          </Text>
          <Text as="p">
            德國達麗與德國體質過敏症及氣喘症防治協會(Deutscher Allergie- und Asthmabund e. V., DAAB)合作，開發了適合過敏人士與敏感肌膚專用的dalli抗敏洗衣系列，用起來備感安心。常有過敏現象的人特別要注意，洗劑的選擇也能影響您的健康。正確的使用dalli抗敏洗衣系列能幫助您減低過敏原的產生，生活更舒適愉快。
          </Text>
        </>
      )
    case 2:
      return (
        <Image src={url + 'misc/care_symbols.jpg'} width="542px" height="549px" />
      )
    case 3:
      return (
        <>
          <Text as="h2">去漬小撇步</Text>
          <Text as="p">
            對於各種不同類型的汙漬的處理方針都是越快越好。一但汙漬乾掉後滲入衣物纖維內，就變得非常難去除了。當沾染到汙漬時，請立即用衛生紙、乾布或是蠟紙按壓吸收，避免其快速滲入衣物纖維中。亦可先沾取少許礦泉水於髒汙之處，然後以毛巾吸乾。
          </Text>
          <Text as="p">
            對於特別頑強的汙漬，於該處沾取少量洗衣精讓其吸收後再以一般洗衣方式清洗即可。
          </Text>
          <Text as="p">
            針對各種不同類型的汙漬處理，就讓最專業的德國達麗提供您幾個小撇步，將汙漬輕鬆去除：
          </Text>
          <Text as="h3">啤酒漬</Text>
          <Text as="p">
            先在髒汙處沾取少量dalli洗衣精(dalli各款液狀產品皆可)，接著按照衣物的洗衣標示清洗，使用dalli全效濃縮洗衣粉效果極佳，其強效去漬能力能有效清除汙垢。對於特別頑強的啤酒漬，您也可以用醋：水(1:4)的比例混合於汙漬處搓洗後再放入洗衣機內。
          </Text>
          <Text as="h3">血漬</Text>
          <Text as="p">
            若是未乾的血漬，可先以冷水清洗，接著以按照一般洗衣方式清洗。
          </Text>
          <Text as="h3">油漆漬</Text>
          <Text as="p">
            水性油漆漬如乳膠、壓克力及乳化漆等，只要其尚未全乾，應能用水將其清洗乾淨。接著按照洗衣標示清洗衣物。
          </Text>
          <Text as="h3">口香糖沾黏</Text>
          <Text as="p">
            將該衣物放入冷凍庫中1小時，待口香糖沾黏變乾，黏性降低，便能輕鬆刮除，再以一般洗衣方式清洗即可。
          </Text>
          <Text as="h3">膠狀汙漬</Text>
          <Text as="p">
            若您的衣物非人造纖維織物，有個小偏方能清除沾黏的膠狀汙漬。用指甲油去光水或丙酮輕拍汙點，接著按照一般洗衣方式清洗。
          </Text>
          <Text as="h3">水果/蔬菜/咖啡/茶漬</Text>
          <Text as="p">
            髒汙處浸泡於冷水中，接著以洗衣粉清洗，毋須使用洗衣精。洗衣粉中共有的除漬成份能將此類汙漬清除乾淨。
          </Text>
          <Text as="h3">油漬</Text>
          <Text as="p">
            於油漬處塗抹少量洗衣精，待其吸收後，搓洗並於清水中洗淨。
          </Text>
          <Text as="h3">鐵鏽漬</Text>
          <Text as="p">
            取適量檸檬汁輕拍髒汙處，灑上鹽並待其吸收；汙漬顏色淡去後請依照一般洗衣方式清洗。
          </Text>
          <Text as="h3">泥巴髒汙</Text>
          <Text as="p">
            首先請待泥巴完全乾燥後將其刮除，在髒汙處抹上洗衣精稍待一會，接著按照洗衣標示說明清洗。高溫的水能更有效去除泥巴髒汙，但仍請遵照洗衣標示說明。
          </Text>
          <Text as="h3">巧克力漬</Text>
          <Text as="p">
            仔細刮除衣物上巧克力漬後，以洗衣精清洗汙漬處，再以一般洗衣方式清洗即可。
          </Text>
          <Text as="h3">油墨漬</Text>
          <Text as="p">
            請將沾染到油墨汙漬的衣物浸泡於加了洗衣精的冷水中至少12小時，接著依照衣物洗衣標示清洗；或者於清洗前輕拍去光水去除亦可幫助除漬(人造纖維衣物請勿使用去光水)。
          </Text>
          <Text as="h3">蠟漬</Text>
          <Text as="p">
            小心的以刀片刮除蠟漬，接著鋪上蠟紙並以熨斗在汙漬處整燙。接著請於肥皂水中刷淨。
          </Text>
        </>
      )
    default:
      return (
        <>
          <Text as="h2">洗衣小訣竅</Text>
          <Text as="p">
            跟隨最專業的德國達麗洗劑的簡單7步驟，完美達成洗衣任務，更棒的是，德國達麗的洗劑同時也是對環境溫柔呵護的!
          </Text>
          <ul>
            <li>洗衣前請詳讀衣物上的洗衣標示說明。</li>
            <li>請依照洗衣產品建議用量參照使用。</li>
            <li>為達最佳效果，可將白色衣物、彩色衣物、高級纖維衣物分開清洗，如此一來洗劑用量達到節省，能源使用也更有效率。</li>
            <li>按照不同衣物選擇不同的達麗專業洗劑。例如：德國達麗全效濃縮洗衣粉，有強效去漬的功能，能除去頑強汙垢。</li>
            <li>注意包裝上的指示說明，參照建議用量來使用。</li>
            <li>愈低溫對衣物愈好，大約攝氏30度的溫度就是最剛好的溫度，如果能避免高溫洗程衣物是較為理想的。</li>
            <li>應照洗衣機指示用量放入衣物，衣物重量超載將影響清潔效果。</li>
          </ul>
          <Text as="p">
            德國達麗通過ISO9001驗證，IFS HPC家用清潔與身體護理產品國際認證，BRC全球標準驗證，為您和家人的健康把關，不遺餘力。
          </Text>
          <Text as="h3">
            德國達麗專業洗劑，不需另外添加衣物柔軟精。
          </Text>
          <Text as="p">
            德國達麗洗衣產品在硬度達30 °dH的水中也能有效率的清洗您的衣物，您可以不必另外添加衣物柔軟精，為您省荷包。
          </Text>
          <Text as="h3">
            以下衣物請不要使用滾動洗程，方能保護衣物
          </Text>
          <Text as="p">
            為了避免衣物褪色及確保完全洗淨，建議您將以下衣物翻面後再清洗：
          </Text>
          <ul>
            <li>較硬纖維衣物，例如牛仔褲。</li>
            <li>枕頭套與床單。</li>
            <li>長褲與夾克外套的口袋可翻出清洗。</li>
            <li>印花紋衣物，黑色衣物，棉質衣物與毛料衣物。</li>
            <li>特殊人造纖維所織成的深色衣物。</li>
          </ul>
          <Text as="h3">
            最專業的達麗洗劑教您如何避免在深色衣物上殘留白色條紋
          </Text>
          <Text as="p">
            讓德國達麗專業洗劑來告訴您如何將洗劑殘留可能性降到最低：
          </Text>
          <ul>
            <li>將衣物捲起，放入洗衣袋內。</li>
            <li>使用液態的洗衣精來清洗此類衣物。</li>
            <li>選擇溫和洗程，避免過度攪動。</li>
            <li>洗衣機每分鐘轉速不超過600rpms。</li>
          </ul>
          <Text as="h3">
            不想要衣物皺巴巴的? 這都是可以避免的!
          </Text>
          <Text as="p">
            您可以在洗衣服前先進行以下步驟，能避免洗完的衣物皺巴巴，影響美觀：
          </Text>
          <ul>
            <li>針對輕柔細緻的衣物採取溫和洗程。</li>
            <li>大型織物與小型織物分開清洗。</li>
            <li>使用柔軟精。</li>
            <li>每次洗衣量(衣物乾重)不要超過2.5公斤。</li>
          </ul>
          <Text as="h3">
            洗完後的衣服又乾又硬嗎? 達麗有辦法!
          </Text>
          <Text as="p">
            有時候，在封閉的室內風乾的衣物常常又乾又硬，難以塑型、折疊。最好的方法就是保持室內通風，並盡量在折疊衣物前將每件衣物分開，避免纏繞。同時，您使用的洗衣精、洗衣粉等和您家中自來水的硬度也和衣物的軟硬度息息相關。若添加太多洗衣產品，恐怕會有所殘留，必須注意。另外，建議您的水硬度若超過30 °dH，則可添加衣物柔軟精與衣物一同清洗。洗衣服時，衣物量多寡也是非常重要的。衣物乾重最多至5公斤，注意不能放入過多容易吸水的衣物。如此一來便能預防衣物洗了之後產生無法拉整的情形。
          </Text>
          <Text as="h3">
            有效率洗衣，讓洗劑不殘留的小撇步
          </Text>
          <Text as="p">
            最關鍵的重點仍在您所洗的衣物總重量。至少要在洗衣機滾筒內留下一定的空間，對於輕柔精緻的衣物如方才所述，我們建議只能放入2.5公斤的衣物(乾重)，讓其有流動的空間。關於洗劑的用量，請參照我們的產品背面的指示，太多或太少對衣物清潔皆沒有幫助。
          </Text>
          <Text as="p">
            關於洗衣機，您必須知道的事:
          </Text>
          <ul>
            <li>洗衣機濾網應定時清理與檢查。</li>
            <li>確認供水系統正常，避免水量不足。</li>
            <li>洗衣機出水口口徑至少需達1公尺，並且不可有凹損。</li>
            <li>當水壓較低時，建議您提高洗衣機內的水量。</li>
          </ul>
        </>
      )
  }
}

export default injectIntl(Laundry)