import React from 'react'
import { initializeState, handleFieldChange, validateForm } from 'utilities/form'
import FormField from 'components/FormField'
import TextInput from 'components/TextInput'
import { request } from 'utilities/graphql'
import { TOKEN_SET, ALERT_ADD } from 'constants/actionType'

export const initialState = initializeState({
  username: '',
  password: '',
})

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
}

export function getModel({ state, setState, translate, session, app }) {
  return {
    form: getForm({ state, setState, translate }),
    handleSubmit: handleSubmit({ state, setState, session, app })
  }
}

function getForm({ state, setState, translate }) {
  return {
    getUsername: () => (
      <FormField errMsg={translate(state.error.username)}>
        <TextInput
          placeholder={translate('login.field.username')}
          value={state.value.username}
          onChange={handleFieldChange(state, setState, 'username', validation.username)}
          hasError={!!state.error.username}
        />
      </FormField>
    ),
    getPassword: () => (
      <FormField errMsg={translate(state.error.password)}>
        <TextInput
          type="password"
          placeholder={translate('login.field.password')}
          value={state.value.password}
          onChange={handleFieldChange(state, setState, 'password', validation.password)}
          hasError={!!state.error.password}
        />
      </FormField>
    )
  }
}

function handleSubmit({ state, setState, session, app }) {
  return ({ onClose }) => async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) { return }

    const { username, password } = state.value
    const variables = { username, password }
    const query = `
      mutation ($username: String!, $password: String!) {
        createUserToken(username: $username, password: $password) {
          accessToken
          refreshToken
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) { return }

    const token = data.createUserToken
    app.dispatch({ type: TOKEN_SET, token })
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'login.success' }
    })
    onClose()
  }
}