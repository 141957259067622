import styled from 'styled-components'
import { space, width, maxWidth, minWidth, height, color, bgColor, flex, flexWrap, display, position, fontSize, colorStyle, variant } from 'styled-system'

const boxes = variant({ key: 'boxes' })

const Box = styled.div`
  box-sizing: border-box;
  ${space}
  ${width}
  ${maxWidth}
  ${minWidth}
  ${height}
  ${color}
  ${bgColor}
  ${position}
  ${display}
  ${flex}
  ${flexWrap}
  ${fontSize}
  ${colorStyle}
  ${boxes}
`

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...bgColor.propTypes,
  ...position.propTypes,
  ...display.propTypes,
  ...flex.propTypes,
  ...fontSize.propTypes,
  ...colorStyle.propTypes,
  ...boxes.propTypes,
}

export default Box