import React from 'react'
import styled, { keyframes } from 'styled-components'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Overlay from 'components/Overlay'

const scaleUp = keyframes`
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`

const scaleDown = keyframes`
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }
`

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: 100vh;
  width: 100vw;
  display: ${props => props.open ? 'block' : 'none'};
  transform: ${props => props.open ? 'scale(1)' : 'scale(0)'};
  opacity: ${props => props.open ? 1 : 0};
  animation: ${props => props.open ? scaleUp : scaleDown} .5s cubic-bezier(0.165, 0.840, 0.440, 1.000);
`

const Content = styled(Box).attrs({
  bg: 'light.0',
})`
  overflow-x: hidden;
`

export default ({ open, onClose, title, children, ...rest }) => {
  const handleClose = e => {
    if (e.target !== e.currentTarget) { return }
    onClose()
  }
  return (
    <>
      <Overlay open={open} />
      <Wrapper open={open} >
        <Flex justifyContent="center" alignItems="center" height="100%" onClick={handleClose}>
          <Content {...rest}>
            {title && <Text variant="dark" p={3} m={2} justifyContent="center">{title}</Text>}
            <Box m={2}>{children}</Box>
          </Content>
        </Flex>
      </Wrapper>
    </>
  )
}