import React from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import Flex from 'components/Flex'

const url = process.env.REACT_APP_STATIC_URL

export default ({ linkUrl, name, primaryImage, price }) => {
  const imageUrl = url + primaryImage
  return (
    <Flex flex={['100%', '50%', '25%']}>
      <Link
        height="318px"
        width={1}
        maxWidth="295px"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        mx={1}
        mb={2}
        bg="light.0"
        color="dark.3"
        url={linkUrl}
        variant="light"
      >
        <img src={imageUrl} alt={name} style={{ maxHeight: '190px', maxWidth: '190px' }} />
        <Text p={3} pt={4}>{name}</Text>
      </Link>
    </Flex>
  )
}