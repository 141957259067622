import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'
import { initialState, getModel } from 'models/activation'
import { STATUS_INACTIVE, STATUS_ACTIVE, STATUS_PENDING } from 'constants/status'

function Activation({ intl, match }) {
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState })

  useEffect(() => {
    document.title = translate('activation.title')
    const { id, code } = match.params
    model.activateMember({ id, code })
  }, [])
  
  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('activation.title') },
          ]}
        />
      </Box>
      <Flex bg="light.1" color="dark.1" px={4} pb={4} flexDirection="column" alignItems="center">
        {(state === STATUS_PENDING) && <Text>{translate('activation.pending')}</Text>}
        {(state === STATUS_INACTIVE) && <Text>{translate('activation.failure')}</Text>}
        {(state === STATUS_ACTIVE) && (
          <>
            <Text>{translate('activation.success')}</Text>
            <Text>{translate('activation.welcome')}</Text>
            <Text>{translate('activation.login')}</Text>
          </>
        )}
      </Flex>
    </Container>
  )
}

export default injectIntl(Activation)