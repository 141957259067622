import React, { useState } from 'react'
import styled from 'styled-components'
import Box from 'components/Box'
import Flex from 'components/Flex'
import BaseLink from 'components/Link'
import Text from 'components/Text'
import Collapsible from 'components/Collapsible'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

const Ul = styled(Box)`
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
`

Ul.defaultProps = {
  as: 'ul',
  m: 0,
  p: 0,
}

const Link = styled(BaseLink)`
  transition: border-color 300ms linear, padding 300ms linear;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`

function renderIcon(Icon, position) {
  if (!Icon) { return null }

  return (
    <Flex
      width="30px"
      height="30px"
      justifyContent="center"
      alignItems="center"
      mr={(position === 'left') ? 3 : 0}
      ml={(position === 'right') ? 3 : 0}
      fontSize={4}
    >
      {Icon}
    </Flex>
  )
}

function renderLink({ label, colors, collapsible, active, LeftIcon, RightIcon, onClick }) {
  return (
    <Link
      colors={colors}
      py={3}
      flex="1"
      alignItems="center"
      collapsible={collapsible}
      active={active}
      onClick={onClick}
    >
      {renderIcon(LeftIcon, 'left')}
      <Text flex="1" fontSize={2}>{label}</Text>
      {renderIcon(RightIcon, 'right')}
    </Link>
  )
}

function ListGroup({ label, LeftIcon, active, colors, children }) {
  const [open, setOpen] = useState(active)

  return (
    <Flex as="li" flexDirection="column">
      {renderLink({
        colors,
        label,
        collapsible: true,
        active,
        LeftIcon,
        RightIcon: open ? <MdArrowDropUp /> : <MdArrowDropDown />,
        onClick: () => setOpen(!open)
      })}
      <Collapsible open={open}>{children}</Collapsible>
    </Flex>
  )
}

function ListItem({ label, LeftIcon, active, onClick, colors }) {
  return (
    <Flex as="li" flexDirection="column">
      {renderLink({
        colors,
        label,
        collapsible: false,
        active,
        LeftIcon,
        onClick
      })}
    </Flex>
  )
}

ListItem.defaultProps = {
  active: false,
  onClick: () => null,
}

function isGroupActive(items, id) {
  if (!items || !id) { return false }

  for (const item of items) {
    if (item.id === id) {
      return true
    }

    const result = isGroupActive(item.items, id)
    if (result) { return result }
  }

  return false
}

function renderGroup(item, activeItemId, onItemClick, colors) {
  return (
    <ListGroup
      colors={colors}
      key={item.id}
      label={item.label}
      LeftIcon={item.icon}
      active={isGroupActive(item.items, activeItemId)}
    >
      <List
        items={item.items}
        activeItemId={activeItemId}
        onItemClick={onItemClick}
      />
    </ListGroup>
  )
}

function renderItem(item, activeItemId, onItemClick, colors) {
  return (
    <ListItem
      key={item.id}
      colors={colors}
      label={item.label}
      LeftIcon={item.icon}
      active={item.id === activeItemId}
      onClick={() => onItemClick(item)}
    />
  )
}

function List({ items, activeItemId, onItemClick, colors, ...rest }) {
  return (
    <Ul {...rest}>
      {items.map(item => (
        item.items ?
          renderGroup(item, activeItemId, onItemClick, colors) :
          renderItem(item, activeItemId, onItemClick, colors)
      ))}
    </Ul>
  )
}

export default List