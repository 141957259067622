import styled from 'styled-components'
import Box from 'components/Box'

const Divider = styled(Box)``

Divider.defaultProps = {
  height: '1px',
  width: 'auto',
  bg: 'light.3',
}

export default Divider
