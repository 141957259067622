import React from 'react'
import { initializeState, handleFieldChange, validateForm } from 'utilities/form'
import FormField from 'components/FormField'
import TextInput from 'components/TextInput'
import { request } from 'utilities/graphql'
import { ALERT_ADD } from 'constants/actionType'

export const initialState = initializeState({
  username: '',
  password: '',
  passwordConfirm: '',
})

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
  ],
  passwordConfirm: [
    { type: 'required', message: 'error.required' },
    { type: 'minLength', val: 6, message: ['error.minLength', { val: 6 }] },
    { type: 'fieldEqual', name: 'password', message: 'signup.error.passNotMatch' },
  ],
}

export function getModel({ state, setState, translate, session }) {
  return {
    form: getForm({ state, setState, translate }),
    handleSubmit: handleSubmit({ state, setState, session })
  }
}

function getForm({ state, setState, translate }) {
  return {
    getUsername: () => (
      <FormField errMsg={translate(state.error.username)}>
        <TextInput
          placeholder={translate('signup.field.username')}
          value={state.value.username}
          onChange={handleFieldChange(state, setState, 'username', validation.username)}
          hasError={!!state.error.username}
        />
      </FormField>
    ),
    getPassword: () => (
      <FormField errMsg={translate(state.error.password)}>
        <TextInput
          type="password"
          placeholder={translate('signup.field.password')}
          value={state.value.password}
          onChange={handleFieldChange(state, setState, 'password', validation.password)}
          hasError={!!state.error.password}
        />
      </FormField>
    ),
    getPasswordConfirm: () => (
      <FormField errMsg={translate(state.error.passwordConfirm)}>
        <TextInput
          type="password"
          placeholder={translate('signup.field.passwordConfirm')}
          value={state.value.passwordConfirm}
          onChange={handleFieldChange(state, setState, 'passwordConfirm', validation.passwordConfirm)}
          hasError={!!state.error.passwordConfirm}
        />
      </FormField>
    )
  }
}

function handleSubmit({ state, setState, session }) {
  return ({ onClose }) => async event => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) { return }

    const { username, password } = state.value
    const variables = { input: { username, password } }
    const query = `
        mutation ($input: MemberInput!) {
          registerMember(input: $input) {
            id
          }
        }
      `
    const [ok] = await request({ query, variables }, { session })
    if (!ok) { return }

    onClose()
    setState(initialState)
    session.dispatch({
      type: ALERT_ADD,
      item: { type: 'success', message: 'signup.success' }
    })
  }
}