import React, { useEffect, useState, useContext } from 'react'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Gallery from 'components/Gallery'
import Image from 'components/Image'
import Divider from 'components/Divider'
import LoadingIcon from 'components/LoadingIcon'
import { initialState, getModel } from 'models/productDetail'

const url = process.env.REACT_APP_STATIC_URL

function getSlides(images) {
  if (!images) { return [] }
  return images.map((item, index) => (
    <Image key={index} src={url + item} height="100%" size="contain" />
  ))
}

function ProductDetail({ intl, match }) {
  const productId = match.params.id
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, session, app, translate })

  useEffect(() => {
    document.title = translate('product.title')
    model.handleFetch({ id: productId })
    model.setBreadcrumb(state)
  }, [state.id])
  
  if (!state.id) {
    return (
      <Box bg="light.0" color="dark.0" width={1} px={[2, 3, 4]}>
        <LoadingIcon />
      </Box>
    )
  }

  return (
    <Box bg="light.0" color="dark.0" px={[2, 3, 4]}>
      <Flex flexWrap="wrap">
        <Box flex={['100%', 1]} mr={[0, 0, 4]}>
          <Gallery
            width="100%"
            minWidth="369px"
            height={['330px', '515px']}
            slides={getSlides(state.images)}
          />
        </Box>
        <Box flex={['100%', 2]} flexDirection="column" py={[3, 3, 4]}>
          <Text mb={3} fontSize={3}>{state.name}</Text>
          {/* <Divider />
          <Text my={3} fontSize={5}>NT. {state.price}</Text> */}
          <Divider />
          <Box my={3}>
            <div dangerouslySetInnerHTML={{ __html: state.spec }} />
          </Box>
          <Divider />
          {/* <SocialList my={3} /> */}
        </Box>
      </Flex>
      <Box my={[3, 3, 4]}>
        <div dangerouslySetInnerHTML={{ __html: state.desc }} />
      </Box>
    </Box>
  )
}

export default injectIntl(ProductDetail)