import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import SessionContext from 'contexts/SessionContext'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Image from 'components/Image'
import Text from 'components/Text'
import Link from 'components/Link'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'
import Table from 'components/Table'
import { initialState, getModel } from 'models/checkout'
import Button from 'components/Button'
import Counter from 'components/Counter'
import { MdDelete } from 'react-icons/md'
import { CART_REMOVE, CART_EDIT } from 'constants/actionType'

const url = process.env.REACT_APP_STATIC_URL

const columns = ({ translate, app }) => [
  {
    id: 'product',
    label: translate('cart.table.product'),
    render: row => {
      const { id, primaryImage, name } = row.product
      return (
        <Flex alignItems="center">
          <Image src={url + primaryImage} width="64px" height="64px" size="contain" />
          <Link url={`/products/${id}`} mx={3}>{name}</Link>
        </Flex>
      )
    }
  },
  {
    id: 'unitPrice',
    label: translate('cart.table.unitPrice'),
    render: row => <Text>NT.{row.product.price}</Text>
  },
  {
    id: 'quantity',
    label: translate('cart.table.quantity'),
    render: row => (
      <Counter
        value={row.quantity}
        onChange={row => quantity => {
          if (quantity <= 1) { quantity = 1 }
          const item = { ...row, quantity }
          app.dispatch({ type: CART_EDIT, item })
        }}
      />
    )
  },
  {
    id: 'price',
    label: translate('cart.table.price'),
    render: row => {
      const { product, quantity } = row
      return <Text>NT.{product.price * quantity}</Text>
    }
  },
  {
    id: 'action',
    align: 'end',
    render: row => (
      <Button variant="plain" onClick={() => app.dispatch({ type: CART_REMOVE, id: row.id })}>
        <MdDelete />
      </Button>
    )
  }
]

function Checkout({ intl, history }) {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const translate = getTranslate(intl)
  const model = getModel({ state, setState, translate, session, app })

  useEffect(() => {
    document.title = translate('cart.title')
  }, [])

  const handleSubmit = async event => {
    setDisableSubmit(true)
    const order = await model.createOrder(event)
    if (!order) { 
      setDisableSubmit(false)
      return 
    }

    const trade = await model.createTrade({ orderId: order.id, totalAmount: order.totalAmount })
    const { merchantId, token } = trade
    history.push(`/payments/${merchantId}/tokens/${token}`)
  }

  return (
    <Container>
      <Box bg="light.1">
        <Breadcrumb
          items={[
            { label: 'Home', url: '/' },
            { label: translate('cart.title') },
          ]}
        />
      </Box>
      <Flex flexWrap="wrap" bg="light.1" color="dark.2" px={[0, 4]} pb={4}>
        <Box flex={['100%', '100%', 3]}>
          <Table
            bg="light.1"
            columns={columns({ translate, app })}
            rows={app.state.cartItems}
          />
          <Flex mt={4} justifyContent="flex-end" alignItems="center">
            <Text>{translate('cart.total.price')}:</Text>
            <Text fontSize={5} mx={3}>NT.{getTotalPrice(app.state.cartItems)}</Text>
          </Flex>
        </Box>
        <Box flex={['100%', '100%', 1]} pl={[0, 4]}>
          <form onSubmit={model.handleSubmit} style={{ width: '100%' }}>
            <Box flex={['100%', 1]} bg="light.0" mb={3}>
              <Text variant="dark" p={2} justifyContent="center">
                {translate('cart.shipping')}
              </Text>
              <Box p={3}>
                {model.form.getName()}
                {model.form.getPhone()}
                {model.form.getAddress()}
              </Box>
            </Box>
            <Box flex={['100%', 1]} bg="light.0" mb={4}>
              <Text variant="dark" p={2} justifyContent="center">
                {translate('cart.memo')}
              </Text>
              <Box p={3}>
                {model.form.getMemo()}
              </Box>
            </Box>
            <Button disabled={disableSubmit} variant="primary" width={1} onClick={handleSubmit}>
              {translate('cart.btn.submit')}
            </Button>
          </form>
        </Box>
      </Flex>
    </Container>
  )
}

function getTotalPrice(cartItems) {
  return cartItems.reduce((total, item) => {
    total += item.product.price * item.quantity
    return total
  }, 0)
}

export default injectIntl(withRouter(Checkout))