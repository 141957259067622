import React from 'react'
import styled from 'styled-components'
import Box from 'components/Box'
import { FaFacebook, FaYoutube } from 'react-icons/fa'

const SocialList = styled(Box).attrs({
  as: 'ul',
  m: 0,
  p: 0,
})`
  list-style: none;
  overflow: auto;
`

const SocialItem = styled(Box).attrs({
  as: 'li',
  mx: 1,
  fontSize: 4,
  color: 'light.3'
})`
  float: left;
`

const Link = styled.a`
  color: ${props => props.theme.colors.light[4]};
  transition: color 0.2s ease-out, margin-left 0.2s ease-out;
  &:hover {
    color: ${props => props.theme.colors.light[0]};
  }
`

export default ({ ...rest }) => (
  <SocialList {...rest}>
    <SocialItem>
      <Link target="_blank" href="https://www.facebook.com/DalliTW/"><FaFacebook /></Link>
    </SocialItem>
    <SocialItem>
      <Link target="_blank" href="https://www.youtube.com/playlist?list=PLWKw_gK5D4RvzgZ_3K2repG710Hv88zSB"><FaYoutube /></Link>
    </SocialItem>
  </SocialList>
)