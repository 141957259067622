import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { getTranslate } from 'utilities/intl'
import AppContext from 'contexts/AppContext'
import { withRouter } from 'react-router'
import { SIDEBAR_TOGGLE } from 'constants/actionType'
import Box from 'components/Box'
import Link from 'components/Link'
import Text from 'components/Text'
import Divider from 'components/Divider'
import List from 'components/List'
import Overlay from 'components/Overlay'

const sidebarWidth = '293px'

const nav = translate => [
  {
    id: 'products',
    label: translate('sidebar.product'),
    path: '/products',
  },
  {
    id: 'environment',
    label: translate('sidebar.environment'),
    path: '/info/environment',
  },
  {
    id: 'safty',
    label: translate('sidebar.safty'),
    path: '/info/safty',
  },
  {
    id: 'quality',
    label: translate('sidebar.quality'),
    path: '/info/quality',
  },
  {
    id: 'laundry',
    label: translate('sidebar.laundry'),
    path: '/info/laundry',
  },
]

const Wrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: ${props => props.open ? sidebarWidth : 0};
  height: 100%;
  background-color: ${props => props.theme.colors.dark[2]};
  overflow: hidden;
  transition: width 0.3s;
`

const Sidebar = ({ intl, history, open, ...rest }) => {
  const { dispatch } = useContext(AppContext)
  const [activeNavId, setActiveNavId] = useState()
  const translate = getTranslate(intl)

  const handleItemClick = item => {
    setActiveNavId(item.id)
    history.push(item.path)
    dispatch({ type: SIDEBAR_TOGGLE })
  }

  return (
    <>
      <Overlay open={open} onClick={() => dispatch({ type: SIDEBAR_TOGGLE })} />
      <Wrapper open={open} {...rest}>
        <Link
          alignItems="center"
          px={4}
          py={3}
          onClick={() => {
            history.push('/')
            dispatch({ type: SIDEBAR_TOGGLE })
          }}
        >
          <Text color="light.0" fontSize={3}>DALLI</Text>
        </Link>
        <Divider mx={3} />
        <List
          colors="light"
          items={nav(translate)}
          activeItemId={activeNavId}
          onItemClick={handleItemClick}
          my={1}
          px={4}
          width={sidebarWidth}
        />
      </Wrapper>
    </>
  )
}

export default withRouter(injectIntl(Sidebar))