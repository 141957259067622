import React, { useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import Box from 'components/Box'
import Text from 'components/Text'
import List from 'components/List'
import { getModel } from 'models/category'
import AppContext from 'contexts/AppContext'
import { CATEGORY_SET } from 'constants/actionType'

const Category = ({ history, ...rest }) => {
  const { state, dispatch } = useContext(AppContext)
  const categories = state.categories
  const model = getModel({ 
    state: categories, 
    setState: items => dispatch({ type: CATEGORY_SET, items }) 
  })

  useEffect(() => {
    model.handleFetch()
  }, [])
  
  const items = categories.map(item => ({
    id: item.id,
    label: item.name,
    path: `/products?category=${item.id}`
  }))

  return (
    <Box {...rest}>
      <Text variant="dark" p={2} justifyContent="center">產品目錄</Text>
      <List
        colors="dark"
        items={items}
        onItemClick={item => history.push(item.path)}
      />
    </Box>
  )
}

export default withRouter(Category)