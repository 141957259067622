export default {
  "error.required": "此欄位為必填欄",
  "error.min": "最小值需為{val}",
  "error.minLength": "最少字符需為{val}",
  "error.maxLength": "最多字符為{val}",
  "error.token.missing": "請先登錄會員",
  "error.user.invalidCredential": "密碼錯誤！",
  "error.auth.memberNotFound": "帳號與密碼不匹配！",
  "btn.submit": "提交",
  'placeholder.textfield': '請輸入{label}',
  'app.name': 'Dalli',
  'app.login': '登錄',
  'app.logout': '登出',
  'app.signup': '註冊',
  'app.login.required': '請先登錄會員！',
  'sidebar.product': '產品目錄',
  'sidebar.environment': '環境與能源',
  'sidebar.safty': '安全與健康',
  'sidebar.quality': '專業品質',
  'sidebar.laundry': '洗衣小百科',
  'home.title': 'Welcome - Dalli',
  'member.error.duplicate': '系統已存在此用戶',
  'category.title': '產品目錄',
  'product.title': '商品詳情 - Dalli',
  'product.btn.addToCart': '放入購物車',
  'product.success.addToCart': '商品放入購物車',
  'cart.title': '購物車',
  'cart.table.product': '商品',
  'cart.table.unitPrice': '單價',
  'cart.table.quantity': '數量',
  'cart.table.price': '總計',
  'cart.total.price': '購買總金額',
  'cart.shipping': '收貨信息',
  'cart.memo': '訂單備註',
  'checkout.field.name': '收件人',
  'checkout.field.phone': '聯絡電話',
  'checkout.field.address': '收件地址',
  'checkout.field.memo': '訂單備註',
  'cart.btn.viewDetail': '查看我的購物車',
  'cart.btn.submit': '去買單',
  'login.title': '登錄用戶',
  'login.field.username': 'EMAIL',
  'login.field.password': '密碼',
  'login.btn.login': '登錄',
  'login.success': '成功登錄',
  'logout.success': '成功登出',
  'signup.title': '註冊用戶',
  'signup.field.username': 'EMAIL',
  'signup.field.password': '密碼',
  'signup.field.passwordConfirm': '確認密碼',
  'signup.btn.signup': '註冊',
  'signup.error.passNotMatch': '密碼與確認密碼不相同',
  'signup.success': '註冊成功，請查看郵箱激活帳戶',
  'activation.title': '會員帳戶激活',
  "activation.pending": "帳戶激活中...",
  "activation.failure": "帳戶激活失敗",
  "activation.success": "帳戶激活成功！",
  "activation.welcome": "歡迎加入慎康商城",
  "activation.login": "您可以登錄帳戶修改個人信息及查看消費訂單！",
  "account.sidebar": "會員目錄",
  "payment.title": "支付訂單",
  "account.title": "用戶帳號",
  'account.field.password': '密碼',
  'account.field.newPassword': '新密碼',
  'account.field.newPasswordConfirm': '確認新密碼',
  'account.error.passNotMatch': '密碼與確認密碼不相同',
  "account.success.editPassword": "成功更改登錄密碼",
  "orderList.title": "訂單管理",
  "orderList.table.orderNo": "訂單號",
  "orderList.table.transDate": "交易日期",
  "orderList.table.totalAmount": "總金額",
  "orderList.table.status": "訂單狀態",
  "orderList.status.pending": "待支付",
  "orderList.status.active": "已付款",
  "orderDetail.title": "訂單詳情",
  "environment.title": "環境與能源",
  "safty.title": "安全與健康",
  "quality.title": "專業品質",
  "laundry.title": "洗衣小百科",
}